import React from 'react';

import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Button from 'undercurrent/Button';

import AlertItem from './AlertItem';
import NoAlerts from './NoAlerts';

/**
 * @typedef AlertListPropsI
 * @property {import('club-sauce/public/notifications/index.raw').LWApiPublicNotificationDetailsResultRawI[]} alerts
 * @property {() => void} onDismissAllClick
 * @property {() => void} onItemClick
 * @property {() => void} onItemDismissClick
 */

/**
 * @param {AlertListPropsI} props
 */
const AlertList = ({
	alerts,
	onDismissAllClick,
	onItemClick,
	onItemDismissClick,
}) => (
	<>
		<Typography component="div" variant="label-xl" fontWeight={500}>
			Alerts
		</Typography>
		{alerts?.length < 1 ? (
			<NoAlerts />
		) : (
			<>
				<Stack flex={1}>
					{alerts?.map((alert) => (
						<AlertItem
							key={alert.id}
							alert={alert}
							onDismissClick={() => onItemDismissClick(alert.id)}
							onClick={() => onItemClick(alert.id)}
						/>
					))}
				</Stack>
				<Stack
					sx={(theme) => ({
						position: 'sticky',
						bottom: 0,
						zIndex: 1,
						paddingBottom: theme.spacing(200),
					})}
					backgroundColor={(theme) => theme.palette.uc.bg.main}
					spacing={100}
				>
					<Divider
						sx={(theme) => ({
							marginX: `${theme.spacing(-200)}!important`,
							color: theme.palette.uc.border.weak,
						})}
					/>
					<Button onClick={onDismissAllClick} variant="secondary" size="small">
						Dismiss All
					</Button>
				</Stack>
			</>
		)}
	</>
);

export default AlertList;
