import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form, Field } from 'react-final-form';

// MUI Components
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import DoubleArrow from '@material-ui/icons/DoubleArrowRounded';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';

// Custom Components
import LWTypography from 'components/common/LWTypography';
import ConfirmCancel from 'components/common/Dialogs/ConfirmCancel';
import InfoBox from 'components/molecules/InfoBox';

const AlertBox = styled(Box)`
	background-color: ${({ theme }) => theme.palette.error.main};
	padding-left: ${({ theme }) => theme.spacing(3)}px;
	padding-right: ${({ theme }) => theme.spacing(3)}px;
	padding-top: ${({ theme }) => theme.spacing(3)}px;
	padding-bottom: ${({ theme }) => theme.spacing(1.5)}px;
	margin-left: -${({ theme }) => theme.spacing(4)}px;
	margin-right: -${({ theme }) => theme.spacing(4)}px;
	margin-top: -${({ theme }) => theme.spacing(4)}px;
	margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

const SDoubleArrow = styled(DoubleArrow)`
	font-size: 3rem;
`;

const required = (value) => (value ? undefined : 'Required');

/** Resuable component shared by three different dialogs */
const ReimageDialogContents = ({
	confirmText,
	isLoading,
	onCancel,
	onConfirm,
	sourceTitle,
	sourceDetails,
	targetTitle,
	targetDetails,
	isBareMetal,
	verb,
}) => {
	return (
		<Form
			onSubmit={onConfirm}
			render={({ handleSubmit, values }) => (
				<form onSubmit={handleSubmit}>
					{isBareMetal && (
						<AlertBox alignItems="center">
							<LWTypography variant="body1" color="palette.common.white">
								Reimaging a bare metal machine will take a minimum of one hour
								to complete. Larger, more powerful machines may require
								additional time.
							</LWTypography>
						</AlertBox>
					)}
					<LWTypography BoxProps={{ pb: 2 }} bold variant="h6">
						{`Server data will be overwritten once ${verb} has begun.`}
					</LWTypography>
					<Grid container>
						<Grid item xs={5}>
							<LWTypography variant="subtitle1">{sourceTitle}</LWTypography>
						</Grid>
						<Grid item xs={2} />
						<Grid item xs={5}>
							<LWTypography variant="subtitle1">{targetTitle}</LWTypography>
						</Grid>
					</Grid>
					{/* This needs to be its own row so that the box height expands correctly */}
					<Grid container alignItems="stretch">
						<Grid item xs={5}>
							<InfoBox details={sourceDetails} height="100%" />
						</Grid>
						<Grid
							item
							xs={2}
							container
							justifyContent="center"
							alignItems="center"
						>
							<SDoubleArrow />
						</Grid>
						<Grid item xs={5}>
							<InfoBox details={targetDetails} height="100%" />
						</Grid>
					</Grid>
					{!isBareMetal && (
						<>
							<LWTypography BoxProps={{ pt: 2, pb: 1.5 }}>
								If the server is not coming back up, enable &apos;Rebuild
								Filesystem&apos; to force a complete rebuild of the filesystem.
							</LWTypography>
							<Field
								name="rebuild"
								type="checkbox"
								render={({ input }) => (
									<FormControlLabel
										control={
											<Checkbox
												color="primary"
												checked={!!input.value}
												{...input}
											/>
										}
										label="Rebuild Filesystem"
									/>
								)}
							/>
							<Box py={1}>
								<Divider />
							</Box>
						</>
					)}
					<Field
						name="confirm"
						type="checkbox"
						validate={required}
						render={({ input }) => (
							<FormControlLabel
								control={
									<Checkbox
										color="primary"
										checked={!!input.value}
										{...input}
									/>
								}
								label={`I confirm and acknowledge ${verb} will permanently overwrite the server’s
      			current state and data.`}
							/>
						)}
					/>
					<ConfirmCancel
						confirmText={confirmText}
						cancel={onCancel}
						confirmType="submit"
						disabled={!values.confirm}
						isLoading={isLoading}
					/>
				</form>
			)}
		/>
	);
};

ReimageDialogContents.props = {
	confirmText: PropTypes.string,
	onCancel: PropTypes.func,
	onConfirm: PropTypes.func,
	sourceTitle: PropTypes.string,
	sourceDetails: PropTypes.array,
	targetTitle: PropTypes.string,
	targetDetails: PropTypes.array,
	isBareMetal: PropTypes.bool,
	verb: PropTypes.string,
};

export { InfoBox };
export default ReimageDialogContents;
