import React, { useEffect, useState } from 'react';
import Tooltip from 'undercurrent/Tooltip';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import EllipsisIcon from 'undercurrent/icons/lucide/Ellipsis';
import HookedLink from '../common/HookedLink';
import useLeftHandLayout from '../context';

/**
 * @param {React.ComponentProps<typeof IconButton>} props
 */
export function LeftNavFooterMenu(props) {
	const [menuElement, setMenuElement] = useState();
	const open = menuElement !== undefined;

	const { appPath } = useLeftHandLayout();

	// Close menu when appPath changes
	useEffect(() => {
		if (appPath === undefined) return;
		setMenuElement(undefined);
	}, [appPath]);

	return (
		<>
			<Tooltip title="Account" placement="top">
				<IconButton
					onClick={({ currentTarget }) => setMenuElement(currentTarget)}
					variant="barebones"
					{...props}
				>
					<EllipsisIcon sx={{ height: '16px', width: '16px' }} />
				</IconButton>
			</Tooltip>
			<Menu
				open={open}
				anchorEl={menuElement}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				onClose={() => setMenuElement()}
			>
				<MenuItem
					component={HookedLink}
					href="/account/billing/pay"
					role="menuitem"
				>
					<ListItemText primary="Pay now" />
				</MenuItem>
				<MenuItem
					component={HookedLink}
					href="/account/billing"
					role="menuitem"
				>
					<ListItemText primary="Billing details" />
				</MenuItem>
				<MenuItem component={HookedLink} href="/account" role="menuitem">
					<ListItemText primary="Account settings" />
				</MenuItem>
			</Menu>
		</>
	);
}

export default LeftNavFooterMenu;
