import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import relativeTime from 'dayjs/plugin/relativeTime';
import { createSelector } from 'reselect';

dayjs.extend(isSameOrBefore);
dayjs.extend(relativeTime);

const getStateSlice = (state) => state.api.sslCertificate.details;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'SSL_CERTIFICATE_DETAILS',
	method: 'POST',
	url: '/ssl/certificate/details.json',
});

const selectors = {
	autorenew: createSelector(defaultSelectors.getStateSlice, (slice) =>
		slice.getIn(['data', 'autorenew_value'], ''),
	),
	cPanelLinked: createSelector(defaultSelectors.getStateSlice, (slice) =>
		slice.getIn(['data', 'cpanel_linked'], ''),
	),
	expiration: createSelector(defaultSelectors.getStateSlice, (slice) => {
		const date = slice.getIn(['data', 'expiration'], '');
		return date ? dayjs(date).fromNow() : null;
	}),
	expired: createSelector(defaultSelectors.getStateSlice, (slice) => {
		const date = slice.getIn(['data', 'expiration'], null);
		return date ? dayjs(date).isSameOrBefore(dayjs(), 'day') : ''; // is before now
	}),
	status: createSelector(defaultSelectors.getStateSlice, (slice) =>
		slice.getIn(['data', 'verification_status'], ''),
	),
	uniqId: createSelector(defaultSelectors.getStateSlice, (slice) =>
		slice.getIn(['data', 'uniq_id'], ''),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
