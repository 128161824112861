import { getIsStorm } from 'banana-stand/parsers';
import {
	createAPIModule,
	createSelectors,
} from 'utility/redux/apiModuleHelpers';

import displayName from 'utility/assetDisplayNames';
import { toInt } from 'utility/format';
import { acronisProductCodes } from 'utility/acronisProductMap';

import { createSelector } from 'reselect';
import get from 'lodash/get';
import has from 'lodash/has';
import hasIn from 'lodash/hasIn';
import isEmpty from 'lodash/isEmpty';
import first from 'lodash/first';

import { categoryMap } from 'modules/primaryCategoryMapping';
import { getQuotaPricePath } from 'utility/redux/selectorHelperFunctions/backups';
import assetStatuses from 'utility/constants/asset/statuses';
import { parseSupportLevel } from 'utility/redux/selectorHelperFunctions/stormHelpers';
import isNumEqualIsh from 'utility/tools/isNumEqualIsh';
import addonCapabilityDetails from 'utility/constants/asset/addonCapabilityDetails';
import { getBeyondHostingPowerStatus } from 'utility/powerStatus';

// ACRONIS
export const ACRONIS_DESTINATION_CAPABILITY =
	'acronisStorageDestinationAcronis';
export const ACRONISQUOTA = 'AcronisStorageQuotaCloud';

export const LW_DESTINATION_CAPABILITY = 'acronisStorageDestinationLiquidWeb';
export const LWQUOTA = 'AcronisStorageQuotaLiquidWeb';

export const DESTINATION = 'AcronisStorageDestination';

export const acronisCapabilityMapping = {
	[LW_DESTINATION_CAPABILITY]: LWQUOTA,
	[ACRONIS_DESTINATION_CAPABILITY]: ACRONISQUOTA,
};

// END ACRONIS

const getStateSlice = (state) => state.api.asset.details;

const moduleKeys = {
	ACRONIS: 'ACRONIS',
	ACTIVITY: 'ACTIVITY',
	MES: 'MES',
	NOCWORX_DETAILS: 'nocWorxDetails',
	STORAGE: 'STORAGE',
};

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'ASSET_DETAILS',
	method: 'POST',
	url: '/asset/details.json',
});

const getFeatureDetails = createSelector(
	defaultSelectors.getNativeState,
	(state) => get(state, ['data', 'featureDetails'], {}),
);

/**
 * @returns {string} 'Enabled' if replication is turned on, otherwise '-'
 */
const getFileReplication = createSelector(
	getFeatureDetails,
	(featureDetails) =>
		get(featureDetails, ['Filerep', 'value']) === 'filerep' ? 'Enabled' : '-',
);

// TODO: replace all invokations of this selector with the one from acronisSelectors.
const getAcronis = createSelector(
	defaultSelectors.getNativeData,
	getFeatureDetails,
	(slice, featureDetails) => {
		if (acronisProductCodes.includes(get(slice, ['data', 'type']))) {
			const toReturn = {};
			toReturn.destination = featureDetails[DESTINATION];

			if (featureDetails[ACRONISQUOTA]) {
				toReturn.quota = featureDetails[ACRONISQUOTA];
			}
			if (featureDetails[LWQUOTA]) {
				toReturn.quota = featureDetails[LWQUOTA];
			}
			return toReturn;
		}
		return {};
	},
);

const acronisSelectors = createSelectors(
	getStateSlice,
	false,
	moduleKeys.ACRONIS,
);

const nocWorxSelectors = createSelectors(
	getStateSlice,
	false,
	moduleKeys.NOCWORX_DETAILS,
);

const mesSelectors = createSelectors(getStateSlice, false, moduleKeys.MES);

const nocWorxServerDetails = createSelector(
	nocWorxSelectors.getNativeData,
	(data) => {
		get(data, ['nocworxRemoteDetails', 'server_details']);
	},
);

const status = createSelector(
	defaultSelectors.getNativeData,
	(slice) => slice?.status || '',
);

/**
 * An index to use to access the data we need in featureDetails obj
 * The keys for nocworx selectors change depending on the context. We have to get these values based on the capabilities, which do not change.
 * @returns {object} with capability string as key and the resulting key to use for featureDetails obj as the value
 *  {
 *   "nocworxVolumeLocation": "CloudStorageLocation",
 *   "nocworxPublicIP": "ExtraIp",
 *   "nocworxCPU": "CloudFlavorCPU",
 *	 ...
 * }
 */
const getFeatureCapabilityMap = createSelector(
	getFeatureDetails, // createMapSelectorArgs doesn't work here because this is an object, not an array.
	(featureDetails) =>
		// Creating a map that allows us to get the unreliable key from the reliable capability.
		Object.fromEntries(
			Object.entries(featureDetails || {}) // the featureDetails object becomes an array of entries. [[key, value]]
				.map(
					(
						[featureKey, obj], // mapping over those entries to get arrays of [['capabilityKey1', 'featureKey'], ['capabilityKey2', 'featureKey']]
					) =>
						Object.keys(obj.capabilities) // array of the capabilities in the feature.
							.filter((value) => obj.capabilities[value] === 1) // We only want the truthy capabilities.
							.map((capabilityKey) => [capabilityKey, featureKey]),
				)
				.flat() // We had nested arrays that need to be flattened: [[['capabilityKey1', 'featureKey'], [...]], [['capabilityKey1', 'featureKey'], [...]]]
				.reverse(), // uses the first one if there are duplicates when this array is finally returned to become the map.
		),
);

/**
 * @returns {string} represents what flavor of nocworx server
 */
const nocworxFlavor = createSelector(
	getFeatureDetails,
	getFeatureCapabilityMap,
	(featureDetails, featureCapabilityMap) =>
		featureDetails?.[featureCapabilityMap.nocworxFlavor]?.value,
);

/**
 * @returns {number} amount of RAM in GB
 */
const nocworxMemory = createSelector(
	getFeatureDetails,
	getFeatureCapabilityMap,
	(featureDetails, featureCapabilityMap) =>
		featureDetails?.[featureCapabilityMap.nocworxMemory]?.value,
);

/**
 * @returns {number} number of cores
 */
const nocworxCPU = createSelector(
	getFeatureDetails,
	getFeatureCapabilityMap,
	(featureDetails, featureCapabilityMap) =>
		featureDetails?.[featureCapabilityMap.nocworxCPU]?.value,
);

/**
 * @returns {object} information about nocworx volume size
 */
const nocworxVolumeSize = createSelector(
	getFeatureDetails,
	getFeatureCapabilityMap,
	(featureDetails, featureCapabilityMap) => {
		const volumeSize =
			featureDetails?.[featureCapabilityMap.nocworxVolumeSize] || {};
		const { value, num_units: numUnits } = volumeSize;
		const newValue = value === 'custom' ? numUnits : value;
		return { ...volumeSize, value: newValue };
	},
);

/**
 * @returns {string} storage volume type
 */
const nocworxVolumeType = createSelector(
	getFeatureDetails,
	getFeatureCapabilityMap,
	(featureDetails, featureCapabilityMap) =>
		featureDetails?.[featureCapabilityMap.nocworxVolumeType]?.description,
);

const templateValue = createSelector(getFeatureDetails, (slice) =>
	get(slice, ['Template', 'value'], null),
);

const templateDescription = createSelector(getFeatureDetails, (slice) =>
	get(slice, ['Template', 'description']),
);

const cPanelLicenseTier = createSelector(
	getFeatureDetails,
	(slice) => slice?.cPanelLicenseTier,
);

const getBandwidth = createSelector(
	getFeatureDetails,
	(slice) => slice?.Bandwidth,
);

const bandwidthOverage = createSelector(
	getFeatureDetails,
	(slice) => slice?.BandwidthOverage,
);

const cPanelLicenseTierValue = createSelector(
	cPanelLicenseTier,
	(slice) => slice?.value,
);

const children = createSelector(defaultSelectors.getNativeState, (slice) =>
	get(slice, ['data', 'children'], []),
);

const domain = createSelector(defaultSelectors.getNativeState, (slice) =>
	get(slice, ['data', 'domain'], ''),
);

const getPrivateParent = createSelector(
	defaultSelectors.getNativeData,
	(data) => data?.privateParent,
);

const getPrivateParentUniqId = createSelector(
	getPrivateParent,
	(parent) => parent?.uniq_id,
);

const ip = createSelector(defaultSelectors.getNativeState, (slice) =>
	get(slice, ['data', 'ip'], ''),
);

const regionId = createSelector(defaultSelectors.getNativeState, (slice) =>
	get(slice, ['data', 'region_id']),
);

const zone = createSelector(defaultSelectors.getNativeState, (slice) =>
	get(slice, ['data', 'zone'], null),
);

const getZoneId = createSelector(zone, (zone_) => zone_?.id);

const regionName = createSelector(zone, (zoneSlice) =>
	get(zoneSlice, ['region', 'name'], null),
);

const regionNameWithZone = createSelector(zone, (zoneSlice) =>
	zoneSlice?.name && zoneSlice?.region?.name
		? `${zoneSlice.region.name} - ${zoneSlice.name}`
		: '',
);

const uniqId = createSelector(defaultSelectors.getNativeState, (slice) =>
	get(slice, ['data', 'uniq_id'], ''),
);

const getPrice = createSelector(
	defaultSelectors.getNativeData,
	(data) => data?.price,
);

const categories = createSelector(
	defaultSelectors.getNativeState,
	(slice) => slice?.data?.categories || [],
);

const isPrivateParent = createSelector(categories, (slice) =>
	slice.includes('PrivateParent'),
);

const capabilities = createSelector(defaultSelectors.getNativeState, (slice) =>
	get(slice, ['data', 'capabilities'], {}),
);

// The serverStatsCloud capability indicates Telegraf
// is reporting system metrics from the parent server,
// outside of the context of the virtual machine
const getCanServerStatsCloud = createSelector(
	capabilities,
	(slice) => Number(slice.serverStatsCloud) === 1,
);

// The serverStatsLocal capability indicates Telegraf
// is reporting system metrics from the server itself,
// meaning it is installed in the customer's environment
const getCanServerStatsLocal = createSelector(
	capabilities,
	(slice) => Number(slice.serverStatsLocal) === 1,
);

const getCanUseSpiceConsole = createSelector(
	capabilities,
	(slice) => Number(slice.canUseSpiceConsole) === 1,
);

const getPreventResizeDown = createSelector(
	capabilities,
	(slice) => Number(slice.preventResizeDown) === 1,
);

/** @typedef {import('utility/constants/asset/addonCapabilityDetails').AddonCapabilityDetail} AddonCapabilityDetail */

/** gets the available addons yet to be put on the asset nested under the type */
const getAddOnTypeToCapabilityToDetails = createSelector(
	capabilities,
	/** @returns {Object.<string, Object.<string, AddonCapabilityDetail>>} */
	(slice) => {
		const availableAddOns = {};
		const addOnRegExp = /^addon.*/;
		Object.entries(slice).forEach(([capability, value]) => {
			if (addOnRegExp.test(capability) && isNumEqualIsh(value, 1)) {
				const addOnDetails = addonCapabilityDetails[capability];
				if (addOnDetails) {
					const { type } = addOnDetails;
					if (!availableAddOns[type]) {
						availableAddOns[type] = {};
					}
					availableAddOns[type][capability] =
						addonCapabilityDetails[capability];
				}
			}
		});
		return availableAddOns;
	},
);

const resourceState = createSelector(defaultSelectors.getNativeState, (slice) =>
	get(slice, ['data', 'resourceState'], []),
);
const diskDetails = createSelector(defaultSelectors.getNativeState, (slice) =>
	get(slice, ['data', 'diskDetails'], []),
);

const mesType = createSelector(defaultSelectors.getNativeState, (slice) =>
	get(slice, ['data', 'mesDetails', 'mes_type'], ''),
);

const mesRole = createSelector(defaultSelectors.getNativeState, (slice) =>
	get(slice, ['data', 'mesDetails', 'mes_role'], ''),
);

const isOpenStack = createSelector(categories, (category) =>
	category.includes('NocworxOpenstack'),
);

const isBeyondHosting = createSelector(categories, (category) =>
	category.includes('BeyondHostingVPS'),
);

const isCloudDedicated = createSelector(categories, (category) =>
	category.includes('CloudBareMetal'),
);

const isCloudVPS = createSelector(categories, (category) =>
	category.includes('CloudVPS'),
);

const canAcronisBackup = createSelector(
	capabilities,
	(slice) => slice?.canAcronisBackup,
);

const powerStatus = createSelector(
	defaultSelectors.getNativeData,
	nocWorxSelectors.getNativeData,
	isBeyondHosting,
	(slice, nocworxData, beyondHosting) =>
		beyondHosting
			? getBeyondHostingPowerStatus(
					get(nocworxData, ['nocworxRemoteDetails', 'power_status']),
				)
			: slice?.powerStatus,
);

const assetType = createSelector(categories, (slice) => {
	if (slice.includes('NocworxOpenstack')) return 'open stack'; // open stack has "VirtualDedicated" as well. TODO: create a better way to reduce the array to assetType. NEWMAN-2104
	const type = slice.reduce(
		(reduction, category) => categoryMap[category] || reduction,
		'',
	);
	return type || 'asset';
});

const getAssetRoute = createSelector(
	domain,
	assetType,
	uniqId,
	(domain_, assetType_, uniqId_) => {
		switch (assetType_) {
			case 'bare metal':
			case 'cloud':
			case 'open stack':
			case 'cloud hosting':
			case 'private parent':
			case 'dedicated':
				return `/servers/${uniqId_}`;
			case 'domain_registration': // Is it possible for assetType to return this value?
				return `/domain/registration/${domain_}`;
			case 'dns_zone':
				return `/domain/dashboard/${domain_}`;
			case 'ssl':
				return `/domain/ssl/dashboard/${domain_}`;
			default:
				return `/${uniqId_}`; // 404
		}
	},
);

const productType = createSelector(defaultSelectors.getNativeState, (slice) =>
	get(slice, ['data', 'type']),
);

const projectName = createSelector(defaultSelectors.getStateSlice, (slice) =>
	get(slice, ['data', 'project_name']),
);

const projectId = createSelector(defaultSelectors.getStateSlice, (slice) =>
	get(slice, ['data', 'project_id']),
);

const isMWPv1 = createSelector(
	productType,
	(productCode) => productCode === 'WP.VM',
);

const isVmware = createSelector(
	productType,
	(productCode) => productCode === 'VMware.vCloud.vDC',
);

const displayType = createSelector(
	defaultSelectors.getData,
	assetType,
	productType,
	(asset, type, productCode) => displayName(type, productCode),
);

const managedType = createSelector(
	getFeatureDetails,
	assetType,
	(slice, type) => {
		switch (type) {
			case 'dedicated':
				return get(slice, ['ControlPanel', 'value'], '');
			case 'cloud':
				return get(slice, ['Template', 'extra_software'], '');
			case 'open stack':
				return get(slice, ['CloudManagement', 'value'], '');
			default:
				return '';
		}
	},
);
const managedLevel = createSelector(
	getFeatureDetails,
	assetType,
	(slice, type) => {
		switch (type) {
			case 'dedicated':
				return get(slice, ['ControlPanel', 'description'], '');
			case 'cloud':
				return get(slice, ['Template', 'support_level'], '');
			case 'open stack':
				return get(slice, ['CloudManagement', 'description'], '');
			default:
				return '';
		}
	},
);
const OS = createSelector(
	getFeatureDetails,
	nocWorxSelectors.getData,
	assetType,
	isBeyondHosting,
	(slice, nocWorxDetails, type, assetIsBeyondHosting) => {
		const serverType = assetIsBeyondHosting ? 'beyondhosting' : type;
		switch (serverType) {
			case 'dedicated':
				return (
					get(slice, ['centos', 'description']) ||
					get(slice, ['OS', 'description'], '')
				);
			case 'cloud':
				return get(slice, ['Template', 'description'], '');
			case 'open stack': {
				const OsOption = Object.values(slice)?.filter((option) =>
					hasIn(option, ['capabilities', 'nocworxImage']),
				);
				if (isEmpty(OsOption)) return null;
				const result = first(
					Object.values(OsOption).map((value) => get(value, 'description')),
				);
				return result;
			}
			case 'beyondhosting': {
				if (!nocWorxDetails) return null;
				return get(
					nocWorxDetails,
					['nocworxRemoteDetails', 'os', 'identity'],
					null,
				);
			}
			default:
				return '';
		}
	},
);

const childType = createSelector(OS, managedLevel, (myOS, myManagedLevel) => {
	if (myOS?.toLowerCase() === 'windows') {
		return 'Windows';
	}

	return parseSupportLevel(myManagedLevel);
});
const RAM = createSelector(
	getFeatureDetails,
	nocWorxServerDetails,
	assetType,
	isPrivateParent,
	isBeyondHosting,
	getPrivateParent,
	(
		slice,
		nocWorxDetails,
		type,
		assetIsPrivateParent,
		assetIsBeyondHosting,
		isChild,
	) => {
		let serverType = assetIsPrivateParent ? 'privateparent' : type;
		serverType = isChild ? 'child' : serverType;
		serverType = assetIsBeyondHosting ? 'beyondhosting' : serverType;
		switch (serverType) {
			case 'dedicated':
				return get(slice, ['RAM', 'description']);
			case 'open stack': {
				const memoryOption = Object.values(slice)?.filter((option) =>
					hasIn(option, ['capabilities', 'nocworxMemory']),
				);
				if (isEmpty(memoryOption)) return null;
				return first(
					Object.values(memoryOption).map((option) => option.description),
				);
			}
			case 'privateparent':
				return get(slice, ['ConfigId', 'memory']);
			case 'cloud':
			case 'child':
				return get(slice, ['ConfigId', 'memory']);
			case 'beyondhosting': {
				if (!nocWorxDetails) return null;
				return `${nocWorxDetails?.ram}MB`;
			}
			default:
				return undefined;
		}
	},
);

const RAMdisplay = createSelector(RAM, (ram) => `${ram} MB`);

const CPU = createSelector(
	getFeatureDetails,
	nocWorxServerDetails,
	assetType,
	isPrivateParent,
	isBeyondHosting,
	getPrivateParent,
	isVmware,
	(
		slice,
		nocWorxDetails,
		type,
		assetIsPrivateParent,
		assetIsBeyondHosting,
		isChild,
		isVmware_,
	) => {
		let serverType = assetIsPrivateParent ? 'privateparent' : type;
		serverType = isChild ? 'child' : serverType;
		serverType = assetIsBeyondHosting ? 'beyondhosting' : serverType;
		serverType = isVmware_ ? 'vmware' : serverType;
		switch (serverType) {
			case 'dedicated':
				return get(slice, ['Processor', 'description'], '');
			case 'cloud': {
				let cores = get(slice, ['ConfigId', 'cpu_cores']);
				if (!cores) cores = get(slice, ['ConfigId', 'vcpu']);

				return cores + (parseInt(cores, 10) > 1 ? ' cores' : ' core');
			}
			case 'open stack': {
				const cpuOption = Object.values(slice)?.filter((option) =>
					hasIn(option, ['capabilities', 'nocworxCPU']),
				);
				if (isEmpty(cpuOption)) return null;
				return first(
					Object.values(cpuOption).map((option) => option.description),
				);
			}
			case 'privateparent': {
				const cores = get(slice, ['ConfigId', 'cpu_cores_per_socket'], '');
				const sockets = get(slice, ['ConfigId', 'cpu_sockets'], '');
				const displayedCores = cores * sockets;
				return (
					displayedCores +
					(parseInt(displayedCores, 10) > 1 ? ' cores' : ' core')
				);
			}
			case 'child': {
				const cores = get(slice, ['ConfigId', 'vcpu'], '');
				return cores + (parseInt(cores, 10) > 1 ? ' cores' : ' core');
			}
			case 'beyondhosting': {
				if (!nocWorxDetails) return null;
				const cores = nocWorxDetails?.cpu;
				return cores + (parseInt(cores, 10) > 1 ? ' cores' : ' core');
			}
			case 'vmware':
				return slice?.vDCvCPU?.num_units
					? `${slice?.vDCvCPU?.num_units} vCPU's`
					: '';
			default:
				return '';
		}
	},
);

const CPUint = createSelector(CPU, (cpu) => toInt(cpu));

const configId = createSelector(
	getFeatureDetails,
	(slice) => slice.ConfigId || {},
);

const storageSize = createSelector(configId, (slice) => toInt(slice.disk));

const storageSizeDisplay = createSelector(
	storageSize,
	(storageSize_) => `${storageSize_} GB`,
);

const diskType = createSelector(configId, (slice) => slice.disk_type);

const configIdValue = createSelector(configId, (slice) => Number(slice.value));

const deployedOnto = createSelector(
	configId,
	getPrivateParent,
	(slice, child) => {
		const category = slice?.category;
		switch (category) {
			case 'bare-metal':
				return 'cloudDedicated';
			case undefined:
				return child?.uniq_id;
			default:
				return 'vps';
		}
	},
);

const hardDriveData = createSelector(
	getFeatureDetails,
	(slice) =>
		Object.entries(slice).filter(
			([key, value]) =>
				key.search(/HD\d/) === 0 && value?.description !== 'No Tertiary Drive',
		) || {},
);

const hardDrives = createSelector(
	hardDriveData,
	assetType,
	getFeatureDetails,
	nocWorxServerDetails,
	isBeyondHosting,
	(slice, type, features, nocWorxDetails, assetIsBeyondHosting) => {
		const serverType = assetIsBeyondHosting ? 'beyondhosting' : type;
		switch (serverType) {
			case 'open stack': {
				const diskOption = Object.values(features)?.filter((option) =>
					hasIn(option, ['capabilities', 'nocworxVolumeSize']),
				);
				if (isEmpty(diskOption)) return [];
				const diskValue = first(
					Object.values(diskOption)?.map((option) => option.num_units),
				);
				const diskDescription = first(
					Object.values(diskOption)?.map((option) => option.description),
				);

				return [
					{
						title: 'Storage',
						value: diskValue ? `${diskValue} GB` : diskDescription,
					},
				];
			}
			case 'beyondhosting': {
				if (!nocWorxDetails) return [];
				return [
					{
						title: 'storage',
						value: `${nocWorxDetails?.disk_space}GB`,
					},
				];
			}
			default: {
				return slice.map((drive) => ({
					title: drive?.[0],
					value: drive?.[1]?.description,
				}));
			}
		}
	},
);

const role = createSelector(getFeatureDetails, assetType, (slice, type) => {
	switch (type) {
		case 'open stack':
			return get(slice, ['CloudRole', 'description']);
		default:
			return '';
	}
});

const flavor = createSelector(getFeatureDetails, assetType, (slice, type) => {
	switch (type) {
		case 'open stack':
			return get(slice, ['CloudFlavor', 'description']);
		default:
			return '';
	}
});

const beyondHostingSize = createSelector(getFeatureDetails, (slice) =>
	get(slice, ['NocworxSKU', 'value']),
);

const networkSummary = createSelector(
	defaultSelectors.getNativeData,
	(slice) => slice?.networkSummary || {},
);

const primaryIp = createSelector(
	networkSummary,
	(slice) => slice?.primary_ip || null,
);

const privateIp = createSelector(
	networkSummary,
	(slice) => slice?.private_ip || null,
);

const totalIps = createSelector(
	networkSummary,
	(slice) => slice?.total_ips || null,
);

const firewallType = createSelector(networkSummary, (slice) =>
	get(slice, ['firewall', 'type'], null),
);

const firewallConfig = createSelector(networkSummary, (slice) =>
	get(slice, ['firewall', 'config'], null),
);

const backupsAndStorage = createSelector(
	defaultSelectors.getNativeData,
	(slice) => slice?.backupsAndStorage || {},
);

const blockStorage = createSelector(
	backupsAndStorage,
	(slice) => slice?.block_storage,
);

const imageCount = createSelector(backupsAndStorage, (slice) =>
	get(slice, ['image', 'count'], 0),
);

const imageName = createSelector(backupsAndStorage, (slice) =>
	get(slice, ['image', 'name'], null),
);

const imageDate = createSelector(backupsAndStorage, (slice) =>
	get(slice, ['image', 'date'], null),
);

const getAcronisId = createSelector(backupsAndStorage, (slice) =>
	get(slice, ['acronis', 'uniq_id'], null),
);

const hasAcronis = createSelector(backupsAndStorage, (slice) =>
	has(slice, 'acronis'),
);

const getAcronisStatus = createSelector(backupsAndStorage, (slice) =>
	get(slice, ['acronis', 'status'], null),
);

const getHasActiveAcronis = createSelector(
	getAcronisStatus,
	hasAcronis,
	(acronisStatus) => hasAcronis && acronisStatus === assetStatuses.ACTIVE,
);

const parentDiskResources = createSelector(
	resourceState,
	diskDetails,
	(slice, disk) => {
		if (!slice) return null;

		const data = slice.find((value) => value.type === 'disk.lvm.volume');
		return data
			? {
					// for display purposes, we need to remove reserved snapshot space from the amounts
					free: data.free - disk.snapshots,
					used: data.used - disk.snapshots,
					total: data.total - disk.snapshots,
				}
			: null;
	},
);

const parentMemoryResources = createSelector(resourceState, (slice) => {
	if (!slice) return null;
	return slice.find((value) => value.type === 'memory') || null;
});

// Such a normal way to doing things...
const backupPlanRaw = createSelector(getFeatureDetails, (slice) =>
	get(slice, ['LiquidWebBackupPlan']),
);

const getBackupPlan = createSelector(
	backupPlanRaw,
	hasAcronis,
	(slice, acronis) => {
		if (acronis) return 'Acronis';
		switch (slice?.value) {
			case 'BackupDay':
			case 'CloudDaily':
			case 'Daily':
				return 'Daily';
			case 'BackupQuota':
			case 'Quota':
				return 'Quota';
			default:
				return 'None';
		}
	},
);

const backupPlanMonthlyPrice = createSelector(
	getFeatureDetails,
	getBackupPlan,
	(slice, backupPlan) => {
		const path = getQuotaPricePath(backupPlan);
		const backupPrice = get(slice, [path, 'price']);
		if (['Daily', 'CloudDaily'].includes(backupPlan)) {
			return backupPrice * (24 * 30.4);
		}
		return backupPrice;
	},
);

const backupPlanIsLegacy = createSelector(
	backupPlanRaw,
	hasAcronis,
	(slice, acronis) => {
		if (acronis) return false;
		switch (slice?.value) {
			case 'BackupDay':
			case 'CloudDaily':
			case 'Daily':
			case 'BackupQuota':
			case 'Quota':
				return true;
			default:
				return false;
		}
	},
);

const backupQuotaValue = createSelector(
	getFeatureDetails,
	(fdn) => fdn?.BackupQuota?.value,
);

const daysToRetain = createSelector(
	defaultSelectors.getNativeState,
	({ data } = {}) => data?.featureDetails?.BackupDay?.num_units || 7,
);

const instance = createSelector(
	defaultSelectors.getNativeState,
	({ data } = {}) => data?.instance,
);

const stormBackupSize = createSelector(instance, (data) => {
	return data?.backup_size ? parseFloat(data.backup_size) : undefined;
});

const stormBackupQuota = createSelector(instance, (data) =>
	data?.backup_quota ? data.backup_quota : undefined,
);

const volumeCostPerMonth = createSelector(getFeatureDetails, (slice) =>
	get(slice, ['VolumeSize', 'price']),
);

const isStorm = createSelector(productType, (slice) => getIsStorm(slice));

/**
 * gets display text for server Backups Page depending on asset type
 * @returns {String} either 'Backups' or 'Backups & Images'
 */
const getBackupsPageTitle = createSelector(assetType, (type) =>
	type === 'dedicated' ? 'Backups' : 'Backups & Images',
);

const selectors = {
	nocWorxSelectors,
	nocworxFlavor,
	nocworxMemory,
	nocworxCPU,
	nocworxVolumeSize,
	nocworxVolumeType,
	getCanServerStatsCloud,
	getCanServerStatsLocal,
	getCanUseSpiceConsole,
	getPreventResizeDown,
	powerStatus,
	nocWorxServerDetails,
	status,
	getFeatureDetails,
	getFileReplication,
	managedType,
	managedLevel,
	childType,
	domain,
	ip,
	instance,
	stormBackupSize,
	stormBackupQuota,
	getAddOnTypeToCapabilityToDetails,
	getPrice,
	categories,
	capabilities,
	assetType,
	getAssetRoute,
	displayType,
	OS,
	children,
	RAM,
	RAMdisplay,
	CPU,
	CPUint,
	hardDrives,
	role,
	flavor,
	uniqId,
	volumeCostPerMonth,
	getPrivateParent,
	getPrivateParentUniqId,
	configId,
	storageSize,
	storageSizeDisplay,
	diskType,
	configIdValue,
	deployedOnto,
	hardDriveData,
	primaryIp,
	privateIp,
	totalIps,
	firewallType,
	firewallConfig,
	backupsAndStorage,
	bandwidthOverage,
	blockStorage,
	imageName,
	imageCount,
	imageDate,
	isCloudDedicated,
	isCloudVPS,
	isPrivateParent,
	isOpenStack,
	isBeyondHosting,
	isMWPv1,
	isVmware,
	acronisSelectors,
	isStorm,
	getBackupsPageTitle,
	mesSelectors,
	beyondHostingSize,
	parentDiskResources,
	parentMemoryResources,
	productType,
	hasAcronis,
	getAcronis,
	getAcronisId,
	getBandwidth,
	getAcronisStatus,
	getHasActiveAcronis,
	zone,
	getZoneId,
	regionId,
	regionName,
	regionNameWithZone,
	cPanelLicenseTier,
	cPanelLicenseTierValue,
	backupPlanMonthlyPrice,
	backupPlanRaw,
	getBackupPlan,
	backupQuotaValue,
	backupPlanIsLegacy,
	daysToRetain,
	templateValue,
	templateDescription,
	projectName,
	projectId,
	mesType,
	mesRole,
	canAcronisBackup,
	getQuotaPricePath,
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors, moduleKeys, getStateSlice };
