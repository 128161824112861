import { useMediaQuery, useTheme } from '@mui/material';

/** @returns {'small' | 'medium' | 'large'} */
export function useScreenSize() {
	const theme = useTheme();

	const matchSmall = useMediaQuery(theme.breakpoints.down('md'));
	const matchMedium = useMediaQuery(theme.breakpoints.down('lg'));

	if (matchSmall) return 'small';
	if (matchMedium) return 'medium';

	return 'large';
}

export default useScreenSize;
