// @ts-check
import React from 'react';

import useProductDetails from 'modules/queries/product/useDetails';
import getMarketplacePrice from 'utility/products/getMarketplacePrice';
import DrawerProductPreview from './DrawerProductPreview';

/** @param {Omit<import('@mui/material').DrawerProps, 'onClose'> & { onClose: () => void, productCode: string, relatedSubaccnt?: string, domain?: string }} props */
const DrawerHookedProductPreview = ({
	onClose,
	open,
	productCode,
	...rest
}) => {
	const { data: productDetails, isLoading } = useProductDetails(
		{
			code: productCode,
			alsowith: ['marketplacePrices'],
		},
		{
			enabled: Boolean(open),
		},
	);

	const {
		marketplacePrices,
		description,
		title,
		sub_title: subtitle,
	} = productDetails || {};
	const { price, priceUnit } = getMarketplacePrice({
		marketplacePrices,
	});

	return (
		<DrawerProductPreview
			onClose={onClose}
			open={open}
			description={description}
			productCode={productCode}
			price={price}
			priceUnit={priceUnit}
			title={title || subtitle || productCode}
			isLoading={isLoading}
			{...rest}
		/>
	);
};

export default DrawerHookedProductPreview;
