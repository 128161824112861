// @ts-check
import { useDispatch, useSelector } from 'react-redux';
import { isBasketAdmin as getIsBasketAdmin } from 'modules/auth/authSelectors';
import { actions as snackbarActions } from 'modules/snackbar';
import contentKeys from 'components/common/Snackbar/contents/contentKeys';

/** generates a call back function to be called upon a successfull "quick add" to the cart */
const useHandleQuickAddSuccess = () => {
	const dispatch = useDispatch();

	const isBasketAdmin = useSelector(getIsBasketAdmin);

	/**
	 * @param {object} param0
	 * @param {string} param0.code
	 * @param {string} [param0.title]
	 */
	const handleQuickAddSuccess = ({ code, title }) => {
		dispatch(
			snackbarActions.pushMessage({
				variant: 'success',
				contentKey: contentKeys.StyledTextEm,
				contentProps: {
					emText: isBasketAdmin ? code : title || code,
					afterText: 'was added to your cart',
					emTextProps: {
						bold: true,
						variant: 'body2',
					},
					textProps: { variant: 'body2' },
				},
			}),
		);
	};

	return handleQuickAddSuccess;
};

export default useHandleQuickAddSuccess;
