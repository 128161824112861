// @ts-check
import 'intro.js/minified/introjs.min.css';
import React, {
	createContext,
	useContext,
	useCallback,
	useMemo,
	useState,
} from 'react';
import introJs from 'intro.js';
import stepConfig from './config';
import TourLoader from './TourLoader';

/**
 * @typedef StartTourOptionsI
 * @prop {() => void} [onStarted]
 * @prop {() => void} [onComplete]
 * @prop {() => void} [onExit]
 */

/**
 * @typedef IntroTargetI
 * @prop {import('./config').IntroTargetT} id
 * @prop {React.MutableRefObject<Element|null>} ref
 * @prop {boolean} isVisible
 */

/**
 * @typedef IntroContextI
 * @prop {ReturnType<import('intro.js').default>} introjs
 * @prop {(steps: import('./config').IntroConfigStepI[], options: StartTourOptionsI) => void} startTour
 * @prop {Record<import('./config').IntroTargetT, IntroTargetI>} introTargets
 * @prop {React.Dispatch<React.SetStateAction<IntroContextI['introTargets']>>} setIntroTargets
 */

const introContext = createContext(
	/** @type {IntroContextI} */ (/** @type {unknown} */ (undefined)),
);

export function useIntro() {
	return useContext(introContext);
}

/**
 * @param {import('react').ComponentProps<'div'>} props
 */
export function IntroProvider(props) {
	const { children } = props;

	const [introjs] = useState(() => introJs());
	const [introTargets, setIntroTargets] = useState({});
	const [tourActive, setTourActive] = useState(false);

	const startTour = useCallback(
		(steps, options) => {
			const { onStarted, onComplete, onExit } = options;

			// Check a tour isnt running already
			if (tourActive || introjs.currentStep() >= 0) return;

			setTourActive(true);
			introjs.setOptions({ steps });
			introjs.start();

			onStarted?.();

			introjs.oncomplete(() => {
				setTourActive(false);
				onComplete?.();
			});

			introjs.onexit(() => {
				setTourActive(false);
				onExit?.();
			});
		},
		[introjs, tourActive],
	);

	const value = useMemo(
		() => ({
			introjs,
			startTour,
			introTargets,
			setIntroTargets,
		}),
		[introjs, startTour, introTargets, setIntroTargets],
	);

	return (
		<introContext.Provider value={value}>
			{children}
			{Object.keys(stepConfig).map((id) => (
				<TourLoader key={id} id={id} />
			))}
		</introContext.Provider>
	);
}

export default useIntro;
