import React from 'react';
import { connect } from 'react-redux';
import compose from 'utility/compose';
import { withStyles } from '@material-ui/core/styles';
import LWLink from 'components/common/LWLink';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import PropTypes from 'prop-types';
import { selectors as appConfigSelectors } from 'modules/appConfig';

const styles = {
	footerSubNav: {},
	title: {},
	navColumn: {},
	link: {},
	listItem: {},
};

const NavSupport = ({ classes, wwwHostname }) => (
	<Grid item className={classes.navColumn}>
		<LWLink to="/support/mycases" darkBackground underline="none">
			Help
		</LWLink>
		<Grid container direction="column" className={classes.footerSubNav}>
			<List>
				<ListItem classes={{ root: classes.listItem }}>
					<LWLink
						to="/support/mycases"
						variant="caption"
						darkBackground
						underline="none"
					>
						My Support Cases
					</LWLink>
				</ListItem>
				<ListItem classes={{ root: classes.listItem }}>
					<LWLink
						to="/servers/migration-center"
						variant="caption"
						darkBackground
						underline="none"
					>
						Migration Center
					</LWLink>
				</ListItem>
				<ListItem classes={{ root: classes.listItem }}>
					<LWLink
						to={`${wwwHostname}/kb/`}
						variant="caption"
						darkBackground
						underline="none"
					>
						Knowledge Base
					</LWLink>
				</ListItem>
				<ListItem classes={{ root: classes.listItem }}>
					<LWLink
						to={`${wwwHostname}/support/tools/`}
						variant="caption"
						darkBackground
						underline="none"
					>
						Support Tools
					</LWLink>
				</ListItem>
			</List>
		</Grid>
	</Grid>
);

NavSupport.propTypes = {
	classes: PropTypes.object.isRequired,
	wwwHostname: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
	wwwHostname: appConfigSelectors.getWwwHostname(state),
});

export default compose(
	connect(mapStateToProps),
	withStyles(styles),
)(NavSupport);
export { NavSupport };
