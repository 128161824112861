import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import LWCard, { types as lwCardTypes } from 'components/common/LWCard';
import LWTypography from 'components/common/LWTypography';
import Services from 'components/Services';
import useMonitoringLoadGraph from 'modules/queries/monitoring/load/useGraph';
import useMonitoringBandwidthGraph from 'modules/queries/monitoring/bandwidth/useGraph';
import useDebouncedWidth from 'utility/hooks/useDebouncedWidth';

const styles = ({ palette: { common } }) => ({
	root: {},
	servicesContainer: {
		margin: '0.4em 0',
	},
	graph: {
		maxWidth: '100%',
	},
	label: {
		display: 'inline',
		paddingLeft: '1rem',
		textTransform: 'uppercase',
	},
	ok: {
		color: common.green3,
	},
	down: {
		color: common.red,
	},
});

const testIds = { Health__Container: 'Health__Container' };
const Health = ({ classes, uniqId, isBareMetal = false }) => {
	const { debounceWidth, ref } = useDebouncedWidth({
		initWidth: 900,
		delay: 1000,
	});

	const { data: loadGraphData, isLoading: isLoadingLoadGraph } =
		useMonitoringLoadGraph({
			uniq_id: uniqId,
			stat: 'load5',
		});
	const { data: bandwidthGraphData, isLoading: isLoadingBandwidthGraph } =
		useMonitoringBandwidthGraph({
			uniq_id: uniqId,
			width: Math.floor(debounceWidth),
		});

	const loadContent = loadGraphData?.content;
	const bandwidthContent = bandwidthGraphData?.content;

	return (
		<LWCard
			classes={{
				root: classes.root,
			}}
			title="Server Health"
			type={lwCardTypes.LIGHT}
			data-testid={testIds.Health__Container}
		>
			<Grid container justifyContent="space-between" ref={ref}>
				{!isBareMetal && (
					<Grid item>
						<LWTypography variant="h3">Server Load</LWTypography>
						{isLoadingLoadGraph && <CircularProgress />}
						{!isLoadingLoadGraph &&
							(loadContent ? (
								<>
									<LWTypography color="textSecondary" variant="caption">
										Load Avg - 5 Min - Current Day
									</LWTypography>
									<img
										src={`data:image/png;base64,${loadContent}`}
										alt="Server Load Graph"
										className={classes.graph}
									/>
								</>
							) : (
								<LWTypography>No server load data available</LWTypography>
							))}
					</Grid>
				)}
				<Grid item>
					<LWTypography variant="h3">Server Bandwidth</LWTypography>
					{isLoadingBandwidthGraph && <CircularProgress />}
					{!isLoadingBandwidthGraph &&
						(bandwidthContent ? (
							<>
								<LWTypography color="textSecondary" variant="caption">
									Bandwidth Avg - Current Month
								</LWTypography>
								<img
									src={`data:image/png;base64,${bandwidthContent}`}
									alt="Server Load Graph"
									className={classes.graph}
									style={{ width: '100%' }}
								/>
							</>
						) : (
							<LWTypography>No bandwidth data available</LWTypography>
						))}
				</Grid>
			</Grid>
			<LWTypography variant="h3">Monitored Services</LWTypography>
			<Services uniqId={uniqId} />
		</LWCard>
	);
};

Health.propTypes = {
	classes: PropTypes.object.isRequired,
	uniqId: PropTypes.string.isRequired,
};

export default withStyles(styles)(Health);

export { Health, testIds };
