// @ts-check
import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Drawer from '@mui/material/Drawer';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { formatCurrency } from 'banana-stand/format';
import { DrawerAbandonCartContents } from 'components/standard/DrawerAbandonCart';
import TypographyOverflow from 'components/standard/TypographyOverflow';
import productConfigActions from 'modules/basket/productConfig/actions';
import productConfigSelectors from 'modules/basket/productConfig/selectors';
import cartSelectors from 'modules/cart/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import Button from 'undercurrent/Button';
import DrawerContent from 'undercurrent/DrawerContent';
import DrawerFooter from 'undercurrent/DrawerFooter';
import DrawerHeader from 'undercurrent/DrawerHeader';
import useHandleQuickAddSuccess from 'utility/hooks/useHandleQuickAddSuccess';

/**
 * @typedef {Object} DrawerProductPreviewProps
 * @property {string} [description]
 * @property {string} [domain]
 * @property {() => void} onClose
 * @property {number} price
 * @property {string} priceUnit
 * @property {string} productCode
 * @property {string} [relatedSubaccnt]
 * @property {boolean} [showConfigure]
 * @property {string} title
 * @property {boolean} [isLoading]
 */

/** @param {Omit<import('@mui/material').DrawerProps, 'onClose'> & DrawerProductPreviewProps} props */
const DrawerProductPreview = ({
	description,
	domain,
	onClose,
	price,
	priceUnit,
	productCode,
	relatedSubaccnt,
	showConfigure = true,
	title,
	isLoading,
	...rest
}) => {
	const dispatch = useDispatch();
	const handleQuickAddSuccess = useHandleQuickAddSuccess();
	const [showAbandonCartContents, setShowAbandonCartContents] = useState(false);

	const isCartLocked = useSelector(cartSelectors.getIsCartLocked);
	const quickaddLoadingMap = useSelector(
		productConfigSelectors.getQuickaddLoadingMap,
	);

	const handleClose = () => {
		setShowAbandonCartContents(false);
		onClose();
	};

	const quickAdd = () => {
		dispatch(
			productConfigActions.quickAddToCart({
				productCode,
				properties: { domain, related_subaccnt: relatedSubaccnt },
				onSuccess: () => handleQuickAddSuccess({ code: productCode, title }),
			}),
		);
	};

	const handleAddToCartClick = () => {
		if (isCartLocked) {
			setShowAbandonCartContents(true);
		} else {
			quickAdd();
		}
	};

	const descriptionArr = description?.split('\n');

	return (
		<Drawer anchor="right" onClose={handleClose} {...rest}>
			{showAbandonCartContents ? (
				<DrawerAbandonCartContents
					onClose={handleClose}
					onAbandoned={quickAdd}
				/>
			) : (
				<>
					<DrawerHeader onClose={handleClose}>Product preview</DrawerHeader>
					<DrawerContent gap={200}>
						<Stack
							gap={100}
							direction="row"
							alignItems="center"
							justifyContent="space-between"
						>
							{isLoading ? (
								<>
									<Skeleton variant="rounded" width={100} height={23} />
									<Skeleton variant="rounded" width={100} height={23} />
								</>
							) : (
								<>
									<TypographyOverflow>{title}</TypographyOverflow>
									<Chip
										color="success"
										label={`${formatCurrency(price)}/${priceUnit}`}
									/>
								</>
							)}
						</Stack>
						{descriptionArr && descriptionArr.length > 0 && (
							<Stack gap={50}>
								<Typography
									variant="label-xl"
									sx={{ color: ({ palette }) => palette.uc.text.weak }}
								>
									Description
								</Typography>
								<Box component="ul" sx={{ margin: 0, pl: 250 }}>
									{descriptionArr.map((descriptionItem) => (
										<li key={descriptionItem}>
											<Typography variant="body-md">
												{descriptionItem}
											</Typography>
										</li>
									))}
								</Box>
							</Stack>
						)}
					</DrawerContent>
					<DrawerFooter sx={{ gap: 100 }}>
						<Button
							variant="primary"
							color="highlight"
							onClick={handleAddToCartClick}
							loading={quickaddLoadingMap[productCode]}
						>
							Add to cart
						</Button>
						{showConfigure && (
							<Button
								LinkComponent={RouterLink}
								// @ts-ignore
								to={`/shop/config/${productCode}`}
								variant="ghost"
							>
								Configure
							</Button>
						)}
					</DrawerFooter>
				</>
			)}
		</Drawer>
	);
};

export default DrawerProductPreview;
