import { SvgIcon, Typography } from '@mui/material';
import { unstable_ClassNameGenerator as ClassNameGenerator } from '@mui/material/className';
import { ArrowUpRight as ArrowUpRightIcon } from 'lucide-react';
import React from 'react';

const endIconClassname = ClassNameGenerator.generate(
	'LWMuiNavFooterLink-endIcon',
);

/**
 * @typedef {import('@mui/material').SxProps<import('@mui/material').Theme>} MuiSxStylesI
 */

/** @type MuiSxStylesI */
const baseHoverStyles = {
	color: (theme) => theme.palette.uc.text.main,
	outline: 'none',
	[`& > .${endIconClassname}`]: {
		color: (theme) => theme.palette.uc.text.main,
	},
};

/** @type MuiSxStylesI */
const baseStyles = {
	height: (theme) => theme.size.sm,
	overflow: 'hidden',
	display: 'flex',
	alignItems: 'stretch',
	justifyContent: 'center',
	color: (theme) => theme.palette.uc.text.medium,
	textDecoration: 'none',
	paddingX: 150,
	paddingY: 50,
	borderRadius: (theme) => theme.borderRadius.roundedSm,
	gap: 100,
	[`& > .${endIconClassname}`]: {
		color: 'transparent',
	},
	':hover': baseHoverStyles,
	':active': baseHoverStyles,
	':focus-visible': {
		outlineOffset: '2px',
		outlineWidth: '2px',
		outlineStyle: 'solid',
		outlineColor: (theme) => theme.palette.uc.highlight.border.main,
	},
};

/**
 * @typedef NavFooterLinkPropsI
 * @property {boolean} active
 * @property {React.Node} children
 */

/**
 * @param {NavFooterLinkPropsI} props
 */
export function NavFooterLink(props) {
	const { component = 'a', sx: sxProp, children, ...anchorProps } = props;

	return (
		<Typography
			as={component}
			sx={{ ...baseStyles, ...sxProp }}
			{...anchorProps}
		>
			<Typography
				component="span"
				variant="body-xs"
				display="block"
				flex="0% 1 1"
				whiteSpace="nowrap"
			>
				{children}
			</Typography>
			<SvgIcon
				sx={{ height: '16px', width: '16px' }}
				className={endIconClassname}
			>
				<ArrowUpRightIcon />
			</SvgIcon>
		</Typography>
	);
}
export default NavFooterLink;
