import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import LWTypography from 'components/common/LWTypography';
import useMonitoringBandwidthGraph from 'modules/queries/monitoring/bandwidth/useGraph';
import useDebouncedWidth from 'utility/hooks/useDebouncedWidth';

const SImg = styled.img`
	width: 100%;
`;

const GraphiteBandwidth = ({ uniqId }) => {
	const { debounceWidth, ref } = useDebouncedWidth({
		initWidth: 900,
		delay: 1000,
	});

	const { data, isLoading } = useMonitoringBandwidthGraph({
		uniq_id: uniqId,
		width: Math.floor(debounceWidth),
	});

	const content = data?.content;

	return (
		<Grid ref={ref}>
			<LWTypography variant="h3">Server Bandwidth</LWTypography>
			{isLoading && <CircularProgress />}
			{!isLoading &&
				(content ? (
					<>
						<LWTypography color="textSecondary" variant="caption">
							Bandwidth Avg - Current Month
						</LWTypography>
						<SImg
							src={`data:image/png;base64,${content}`}
							alt="Server Load Graph"
						/>
					</>
				) : (
					<LWTypography>No bandwidth data available</LWTypography>
				))}
		</Grid>
	);
};

GraphiteBandwidth.propTypes = {
	uniqId: PropTypes.string.isRequired,
};

export default GraphiteBandwidth;
