// @ts-check
import { useQuery } from '@tanstack/react-query';
import api from '../api';

/**
 * @param {import('club-sauce/public/secureDataShare/index.raw').LWApiPublicSecureDataShareListParamsRawI} params
 * @param {object} [options]
 * @param {import('@tanstack/react-query').UseQueryOptions['enabled']} [options.enabled]
 * @param {number} [options.refetchInterval]
 */
const useSecureDataShareList = (params, options) =>
	useQuery({
		queryKey: ['/secureDataShare/list', params],
		queryFn: () => api.secureDataShare.apiRaw.list(params),
		...options,
	});

export default useSecureDataShareList;
