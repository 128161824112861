import marketplaceActions from './actions';

const initialState = {
	selectedTags: {},
	itemsPerPage: 8,
	productCode: '',
	page: 1,
};

const marketplace = (state = initialState, action) => {
	switch (action.type) {
		case marketplaceActions.SET_MARKETPLACE_TAGS:
			return {
				...state,
				selectedTags: {
					...state.selectedTags,
					[action.key]: action.value?.length ? action.value : undefined,
				},
				page: 1,
			};
		case marketplaceActions.SET_MARKETPLACE_ITEMS_PER_PAGE: {
			// used for pagination.
			return {
				...state,
				itemsPerPage: action.itemsPerPage,
			};
		}
		case marketplaceActions.SET_MARKETPLACE_SELECTED_PRODUCT:
			return { ...state, productCode: action.productCode };
		case marketplaceActions.CLEAR_MARKETPLACE_SELECTED_PRODUCT:
			return { ...state, productCode: '' };
		case marketplaceActions.SET_MARKETPLACE_PAGE:
			return { ...state, page: action.page };
		case marketplaceActions.MARKETPLACE_CLEAR:
			return initialState;
		default:
			return state;
	}
};

export default marketplace;
