import dataCenterMap from 'containers/pages/shop/ConfigPackage/dataCenterMap';

const getLocation = ({ regionId, zone }) => {
	const regionName =
		regionId && dataCenterMap[regionId]
			? `${dataCenterMap[regionId].label} ${dataCenterMap[regionId].zone}`
			: '';
	return zone?.name ? `${regionName} - ${zone.name}` : regionName;
};

export default getLocation;
