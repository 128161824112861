import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import styled, { css } from 'styled-components';

import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

import SubNav from 'components/structural/SubNav';
import LWTypography from 'components/common/LWTypography';
import FavoritesToggle from 'components/structural/FavoritesToggle';
import OutlinedText from 'components/atoms/OutlinedText';

const styles = (theme) => ({
	titleWrapper: {
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5em',
		},
	},
	title: {
		paddingTop: theme.spacing(2),
		fontSize: '2.5em',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.5em',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '2em',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1em',
		},
		margin: 'auto .3em auto 0',
		color: theme.palette.primary.main,
		fontWeight: '600',
	},
	subTitle: {
		color: theme.palette.primary.main,
	},
});

const SMainContent = styled.div`
	${({ theme }) => css`
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: ${theme.spacing(4)}px;

		@media (min-width: ${theme.breakpoints.values.md}px) {
			flex-direction: row;
		}
	`}
`;

const Body = styled.div`
	display: flex;
	flex-direction: column;
	flex: 3;
`;

const Side = styled.div`
	flex: 1;
`;

const testIds = {
	pageOverviewSubtitle: 'pageOverviewSubtitle',
};

const MainContent = ({
	classes,
	createIcon = null,
	sideContent,
	sideContentProps,
	name,
	hideFavorite = false,
	favoritesDisplayName = null,
	subTitle,
	subNavData = null,
	bottomContent = null,
	infoChipText,
	isLoading,
	showTitleWhileLoading = false,
}) => {
	return (
		<SMainContent>
			<Body>
				{name && (
					<div className={classes.titleWrapper}>
						<LWTypography
							BoxProps={{ mr: 0.5 }}
							color="palette.primary.main"
							isLoading={isLoading && !showTitleWhileLoading}
							variant="h1"
							SkeletonProps={{ width: 150 }}
						>
							{createIcon && (
								<>
									{createIcon({
										size: 'large',
										color: 'inherit',
									})}
								</>
							)}{' '}
							{name}
						</LWTypography>
						{infoChipText && <OutlinedText text={infoChipText} />}
						{name && !hideFavorite && (
							<FavoritesToggle displayName={favoritesDisplayName || name} />
						)}
					</div>
				)}
				{subTitle && !isLoading && (
					<LWTypography
						className={classes.subTitle}
						variant="subtitle2"
						data-testid={testIds.pageOverviewSubtitle}
					>
						{subTitle}
					</LWTypography>
				)}
				{subNavData && (
					<div className={classes.titleWrapper}>
						<SubNav subNavData={subNavData} />
					</div>
				)}
				{bottomContent && !isLoading && <div>{bottomContent}</div>}
			</Body>
			{sideContent && <Side {...sideContentProps}>{sideContent}</Side>}
		</SMainContent>
	);
};

MainContent.propTypes = {
	classes: PropTypes.object.isRequired,
	createIcon: PropTypes.func,
	name: PropTypes.string.isRequired,
	subTitle: PropTypes.string,
	subNavData: PropTypes.oneOfType([
		PropTypes.shape({
			name: PropTypes.string,
			path: PropTypes.string,
			component: PropTypes.object,
		}),
		PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string,
				path: PropTypes.string,
				component: PropTypes.object,
			}),
		),
	]),
	bottomContent: PropTypes.object,
	favoritesDisplayName: PropTypes.string,
	hideFavorite: PropTypes.bool,
	showTitleWhileLoading: PropTypes.bool,
};

export { MainContent, testIds };

export default compose(withStyles(styles), withWidth())(MainContent);
