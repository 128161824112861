// @ts-check

import React from 'react';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import useSearchAutocomplete from './hooks/useSearchAutocomplete';
import SearchInput from './components/SearchInput';
import SearchResults from './components/SearchResults';
import SearchFooter from './components/SearchFooter';

const StyledDialogContent = styled(Box)(({ theme }) => ({
	position: 'fixed',
	inset: 0,
	backgroundColor: theme.palette.uc.bg.main,
	padding: theme.spacing(300),
	zIndex: 1001,
	top: 0,

	[theme.breakpoints.up('md')]: {
		borderRadius: theme.borderRadius.roundedSm,
		top: 72,
		left: '50%',
		transform: 'translateX(-50%)',
		width: '80%',
		maxWidth: 1000,
		boxShadow: theme.shadows[3],
		height: 'fit-content',
		maxHeight: `calc(100vh - ${theme.spacing(18)})`,
	},
}));

const ResultsPanel = styled(Box)(({ theme }) => ({
	maxHeight: `calc(100vh - 178px)`,
	overflow: 'auto',
	marginTop: theme.spacing(300),
	[theme.breakpoints.up('md')]: {
		maxHeight: `calc(100vh - ${theme.spacing(900)} * 2 - 220px)`,
	},
}));

/**
 * Props for the SearchModal component
 * @typedef SearchModalProps
 * @property {boolean} open - Whether the search modal is open
 * @property {() => void} onClose - Function to close the modal
 */

/**
 * Props for the Autocomplete component
 * @typedef AutocompleteProps
 * @property {() => void} onClose - Function to close the modal
 */

/**
 * Internal component that handles the autocomplete search functionality
 * Manages the search input, results display, and keyboard navigation
 * @param {AutocompleteProps} props
 * @returns {JSX.Element} Autocomplete component
 */
function Autocomplete({ onClose }) {
	const {
		inputRef,
		formRef,
		panelRef,
		autocomplete,
		autocompleteState,
		activeInput,
		handleKeyDown,
		inputProps,
		formProps: rawFormProps,
		panelProps: rawPanelProps,
	} = useSearchAutocomplete({ onClose });

	// Convert event types to React event types
	const formProps = {
		/** @param {import('react').FormEvent} event */
		onSubmit: (event) => rawFormProps.onSubmit(event.nativeEvent),
		/** @param {import('react').FormEvent} event */
		onReset: (event) => rawFormProps.onReset(event.nativeEvent),
	};

	const panelProps = {
		/** @param {import('react').MouseEvent} event */
		onMouseDown: (event) => rawPanelProps.onMouseDown(event.nativeEvent),
	};

	React.useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}

		document.body.classList.add('search-modal-open');
		return () => {
			document.body.classList.remove('search-modal-open');
		};
	}, [inputRef]);

	return (
		<StyledDialogContent>
			<form ref={formRef} {...formProps}>
				<SearchInput
					inputRef={inputRef}
					inputProps={inputProps}
					activeInput={activeInput}
					/** @param {import('react').KeyboardEvent} event */
					onKeyDown={(event) => handleKeyDown(event.nativeEvent)}
					onClose={onClose}
				/>
			</form>

			{autocompleteState.isOpen && (
				<ResultsPanel ref={panelRef} {...panelProps}>
					<SearchResults
						collections={autocompleteState.collections}
						autocomplete={autocomplete}
						query={autocompleteState.query}
					/>
					{autocompleteState.query && (
						<SearchFooter
							query={autocompleteState.query}
							activeInput={activeInput}
						/>
					)}
				</ResultsPanel>
			)}
		</StyledDialogContent>
	);
}

/**
 * Modal component that provides a search interface with autocomplete functionality
 * Wraps the Autocomplete component in a Material-UI Dialog
 * @param {SearchModalProps} props
 * @returns {JSX.Element|null} SearchModal component or null if not open
 */
export default function SearchModal({ open, onClose }) {
	if (!open) return null;

	return (
		<Dialog open={open} onClose={onClose}>
			<Autocomplete onClose={onClose} />
		</Dialog>
	);
}
