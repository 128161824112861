import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import snackbarActions from 'modules/snackbar/snackbarActions';
import { selectors as targetPowerSelectors } from 'modules/server/targetPower';
import dialogActions from 'modules/dialogs/actions';
import { actions as taskSchedulerActions } from 'modules/server/taskScheduler';
import {
	getTargetPowerAction,
	isPowerTransitioning,
	isRebooting,
	isRunning,
	isShutdown,
	isShuttingDown,
	isStarting,
	POWER_ACTION,
} from 'utility/powerStatus';
import useServerStart from 'modules/queries/server/useStart';
import useServerShutdown from 'modules/queries/server/power/useShutdown';
import { useQueryClient } from '@tanstack/react-query';
import { SLWButton } from '../actionStyles';

/** @param {import('modules/queries/asset/useDetails').AssetDetails} props */
const CloudActions = ({
	domain,
	uniq_id: uniqId,
	capabilities,
	status,
	powerStatus,
}) => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	const { mutate: startServer, isLoading: startIsLoading } = useServerStart();
	const { mutate: shutdownServer, isLoading: shutdownIsLoading } =
		useServerShutdown();

	const handleConfirmStart = () => {
		startServer(
			{ uniq_id: uniqId },
			{
				onSuccess: () => {
					dispatch(
						snackbarActions.pushMessage({
							variant: 'success',
							message: 'Server started',
						}),
					);
				},
			},
		);
	};

	const handleConfirmShutdown = () => {
		shutdownServer(
			{ uniq_id: uniqId },
			{
				onSuccess: () => {
					dispatch(
						snackbarActions.pushMessage({
							variant: 'success',
							message: 'Server shutdown',
						}),
					);
				},
			},
		);
	};

	const canAutoReboot = !!capabilities?.autoReboot;

	const targetPower = useSelector(targetPowerSelectors.getTargetPower);

	const targetPowerAction = getTargetPowerAction(targetPower, uniqId);

	const powerTransitioning = isPowerTransitioning(
		targetPower,
		uniqId,
		powerStatus,
	);

	const showReboot =
		Boolean(capabilities?.autoReboot) &&
		(isRunning(powerStatus) ||
			isShuttingDown(powerStatus) ||
			isRebooting(powerStatus) ||
			(powerTransitioning && targetPowerAction === POWER_ACTION.REBOOT));

	const showStart =
		Boolean(capabilities?.autoReboot) &&
		Boolean(capabilities?.uiCanStart) &&
		(isShutdown(powerStatus) ||
			isStarting(powerStatus) ||
			(powerTransitioning && targetPowerAction === POWER_ACTION.START));

	const showShutdown =
		Boolean(capabilities?.autoShutdown) &&
		Boolean(capabilities?.uiCanShutdown) &&
		(isRunning(powerStatus) ||
			isShuttingDown(powerStatus) ||
			isRebooting(powerStatus) ||
			(powerTransitioning && targetPowerAction === POWER_ACTION.SHUTDOWN));

	// TODO DEV-3833 - should happen whenever reboot/resize/reset mutation is successful
	const rebootCallback = () =>
		queryClient.invalidateQueries({
			queryKey: ['/asset/details', { uniq_id: uniqId }],
		});

	const openServerRebootDialog = () => {
		dispatch(
			taskSchedulerActions.openDialog({
				apiParams: { uniq_id: uniqId },
				hostname: domain,
				task: 'reboot',
				canAutoReboot,
				callback: rebootCallback,
			}),
		);
	};

	const openServerStartDialog = () => {
		dispatch(
			dialogActions.open({
				title: 'Confirm Start',
				contentKey: 'ServerStartDialogContents',
				contentProps: { uniqId, onConfirm: handleConfirmStart },
			}),
		);
	};

	const openServerShutdownDialog = () => {
		dispatch(
			dialogActions.open({
				title: 'Confirm Shutdown',
				contentKey: 'ServerShutdownDialogContents',
				contentProps: { uniqId, onConfirm: handleConfirmShutdown },
			}),
		);
	};

	return (
		<>
			{showReboot && (
				<SLWButton
					onClick={openServerRebootDialog}
					color="secondary"
					variant="outlined"
					isLoading={
						powerTransitioning
							? targetPowerAction === POWER_ACTION.REBOOT
							: isRebooting(powerStatus)
					}
					disabled={
						status !== 'Active' || !isRunning(powerStatus) || powerTransitioning
					}
				>
					Reboot
				</SLWButton>
			)}
			{showStart && (
				<SLWButton
					onClick={openServerStartDialog}
					color="secondary"
					variant="outlined"
					isLoading={
						startIsLoading ||
						(powerTransitioning
							? targetPowerAction === POWER_ACTION.START
							: isStarting(powerStatus))
					}
					disabled={
						status !== 'Active' ||
						!isShutdown(powerStatus) ||
						powerTransitioning
					}
				>
					Start
				</SLWButton>
			)}
			{showShutdown && (
				<SLWButton
					onClick={openServerShutdownDialog}
					color="secondary"
					variant="outlined"
					isLoading={
						shutdownIsLoading ||
						(powerTransitioning
							? targetPowerAction === POWER_ACTION.SHUTDOWN
							: isShuttingDown(powerStatus))
					}
					disabled={
						status !== 'Active' || !isRunning(powerStatus) || powerTransitioning
					}
				>
					Shutdown
				</SLWButton>
			)}
		</>
	);
};

CloudActions.propTypes = {
	powerStatus: PropTypes.string,
};

export default CloudActions;
