// @ts-check
import React, { useRef, useImperativeHandle } from 'react';
import { useIntroTarget } from './useIntroTarget';

/**
 * @typedef IntroTargetPropsI
 * @prop {import('./config').IntroTargetT} introId
 * @prop {JSX.Element} children
 */

export const IntroTarget = React.forwardRef(
	/**
	 * @param {IntroTargetPropsI} props
	 */
	(props, ref) => {
		const { children, introId, ...childProps } = props;

		const childRef = useRef(null);
		useImperativeHandle(ref, () => childRef.current);

		useIntroTarget(introId, childRef);

		return React.cloneElement(children, {
			...childProps,
			ref: childRef,
		});
	},
);
IntroTarget.displayName = 'IntroTarget';

export default IntroTarget;
