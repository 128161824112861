import React from 'react';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Button from 'undercurrent/Button';
import XIcon from 'undercurrent/icons/lucide/X';

import useNotificationResolve from 'modules/queries/notification/useResolve';

/**
 * @typedef DismissAllAlertsDialogProps
 * @property {number[]} notificationIds
 * @property {boolean} open
 * @property {() => void} onCloseClick
 * @property {import('@mui/material/Dialog').DialogProps} rest
 */

/**
 * @param {DismissAllAlertsDialogProps & import('@mui/material/Dialog').DialogProps} props
 */
const DismissAllAlertsDialog = ({
	notificationIds = [],
	open,
	onCloseClick,
	...rest
}) => {
	const { mutate: resolveNotification, isLoading } = useNotificationResolve();

	const handleSubmit = () => {
		notificationIds.forEach((id) => resolveNotification({ id }));
		onCloseClick();
	};

	return (
		<Dialog open={open} onClose={onCloseClick} {...rest}>
			<DialogTitle>
				<Stack
					flexDirection="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<Box>Dismiss all alerts</Box>
					<IconButton variant="barebones" onClick={onCloseClick}>
						<XIcon />
					</IconButton>
				</Stack>
			</DialogTitle>
			<DialogContent>
				<Typography variant="body-md">
					Are you sure you wish to dismiss all of your current alerts? You
					won&apos;t be able to access them again once dismissed.
				</Typography>
			</DialogContent>
			<DialogActions sx={{ justifyContent: 'end' }}>
				<Button variant="ghost" onClick={onCloseClick} loading={isLoading}>
					Cancel
				</Button>
				<Button color="danger" onClick={handleSubmit} loading={isLoading}>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DismissAllAlertsDialog;
