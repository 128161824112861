/** Decode jwt token */
export function jwtDecode(token) {
	return JSON.parse(atob(token.split('.')[1]));
}

/** Decode jwt token without throwing error */
export function tryJwtDecode(token, defaultValue = undefined) {
	try {
		return jwtDecode(token) || defaultValue;
	} catch (e) {
		return defaultValue;
	}
}

export default jwtDecode;
