// @ts-check

import DOMPurify from 'dompurify';

/** Default maximum length for highlighted snippets */
export const DEFAULT_MAX_SNIPPET_LENGTH = 62;

/**
 * Removes all HTML tags from a string using DOMPurify
 * @param {string} str - The string containing HTML tags
 * @returns {string} The string with all HTML tags removed
 * @example
 * stripHtmlTags("<p>Hello <b>World</b></p>") // returns "Hello World"
 */
export function stripHtmlTags(str) {
	if (!str) return '';
	// Use DOMPurify to safely remove all HTML tags and decode entities
	const clean = DOMPurify.sanitize(str, {
		ALLOWED_TAGS: [],
		ALLOWED_ATTR: [],
		ALLOW_ARIA_ATTR: false,
	}).trim();

	return clean.replace(/\s+/g, ' ');
}

/**
 * Finds the position of the highlighted text in the content
 * @param {string} text - The text to search in
 * @returns {{ start: number, end: number } | null} The start and end positions of the highlight, or null if not found
 */
function findHighlightPosition(text) {
	const start = text.indexOf('<em>');
	if (start === -1) return null;

	// Search for closing tag after the opening tag position
	const end = text.indexOf('</em>', start);
	return end === -1 ? null : { start, end: end + 5 }; // +5 to include '</em>'
}

/**
 * Creates a snippet without highlight
 * @param {string} text - The text to create snippet from
 * @param {number} maxLength - Maximum length of the snippet
 * @returns {string} The snippet with ellipsis if needed
 */
function createSimpleSnippet(text, maxLength) {
	const cleanText = stripHtmlTags(text);
	return cleanText.length <= maxLength
		? cleanText
		: `${cleanText.substring(0, maxLength)}...`;
}

/**
 * Adds ellipsis to text based on position
 * @param {string} text - The text to add ellipsis to
 * @param {number} start - Start position in original text
 * @param {number} end - End position in original text
 * @param {number} totalLength - Total length of original text
 * @returns {string} Text with ellipsis added where needed
 */
function addEllipsis(text, start, end, totalLength) {
	const prefix = start > 0 ? '...' : '';
	const suffix = end < totalLength ? '...' : '';
	return prefix + text + suffix;
}

/**
 * Calculates the snippet boundaries
 * @param {Object} params - Parameters for calculation
 * @param {number} params.highlightStart - Start position of highlight in clean text
 * @param {number} params.highlightLength - Length of highlighted text
 * @param {number} params.maxLength - Maximum length of snippet
 * @param {number} params.totalLength - Total length of text
 * @returns {{ start: number, end: number }} The start and end positions for the snippet
 */
function calculateSnippetBoundaries({
	highlightStart,
	highlightLength,
	maxLength,
	totalLength,
}) {
	// Calculate remaining space after accounting for highlighted text
	const remainingLength = maxLength - highlightLength;
	// Distribute remaining space evenly before and after highlight
	const sidePadding = Math.floor(remainingLength / 2);

	let start = Math.max(0, highlightStart - sidePadding);
	let end = Math.min(
		totalLength,
		highlightStart + highlightLength + sidePadding,
	);

	// Handle edge cases when highlight is near the start or end
	if (start === 0) {
		// If at start, extend the end to use full maxLength
		end = Math.min(totalLength, maxLength);
	} else if (end === totalLength) {
		// If at end, adjust start to use full maxLength
		start = Math.max(0, totalLength - maxLength);
	}

	return { start, end };
}

/**
 * Extracts and formats a highlighted snippet from a search result
 * @param {Object} snippetResult - The search result object from Algolia
 * @param {Object} snippetResult.content - The content object containing the search result
 * @param {string} snippetResult.content.value - The actual content string with highlight markers
 * @param {number} [maxSnippetLength=DEFAULT_MAX_SNIPPET_LENGTH] - Maximum length of the snippet
 * @returns {string} A formatted snippet with ellipsis and highlighting preserved
 * @example
 * const result = {
 *   content: {
 *     value: "This is a long text with a <em>highlighted</em> word in the middle"
 *   }
 * };
 * getHighlightedSnippet(result) // returns "...text with a highlighted word in..."
 * getHighlightedSnippet(result, 30) // returns shorter snippet
 */
export function getHighlightedSnippet(
	snippetResult,
	maxSnippetLength = DEFAULT_MAX_SNIPPET_LENGTH,
) {
	if (!snippetResult?.content?.value) return '';

	const text = snippetResult.content.value;
	const highlightPos = findHighlightPosition(text);

	// If no highlight found, return a simple snippet
	if (!highlightPos) {
		return createSimpleSnippet(text, maxSnippetLength);
	}

	// Split text into parts to maintain highlight position after cleaning
	const beforeHighlight = text.substring(0, highlightPos.start);
	const highlightedText = text.substring(highlightPos.start, highlightPos.end);

	// Clean each part separately to maintain correct positions
	const cleanBefore = stripHtmlTags(beforeHighlight);
	const cleanHighlight = stripHtmlTags(highlightedText);
	const cleanText = stripHtmlTags(text);

	if (cleanText.length <= maxSnippetLength) return cleanText;

	// Calculate optimal snippet boundaries to center around highlight
	const boundaries = calculateSnippetBoundaries({
		highlightStart: cleanBefore.length,
		highlightLength: cleanHighlight.length,
		maxLength: maxSnippetLength,
		totalLength: cleanText.length,
	});

	const snippet = cleanText.substring(boundaries.start, boundaries.end);
	return addEllipsis(
		snippet,
		boundaries.start,
		boundaries.end,
		cleanText.length,
	);
}
