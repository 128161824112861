import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router';
import useSnackbarError from 'utility/hooks/useSnackbarError';
import snackbarActions from 'modules/snackbar/snackbarActions';
import api from '../../api';

/** @param {import('@tanstack/react-query').UseMutationOptions} */
const useSupportRequestCreate = ({ onSuccess = () => {} } = {}) => {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	const snackbarError = useSnackbarError();
	const history = useHistory();

	return useMutation({
		mutationFn:
			/** @param {import('club-sauce/public/support/request/index.raw').LWApiPublicSupportRequestCreateParamsRawI} params */
			(params) => api.support.request.apiRaw.create(params),
		onSuccess: (data, params) => {
			onSuccess(data);

			params.cc_list.forEach((email) => {
				if (!data.cc_list.includes(email)) {
					dispatch(
						snackbarActions.pushMessage({
							message: `We were unable to save ${email} to the cc email list. Did you enter in a valid email?`,
							variant: 'error',
						}),
					);
				}
			});

			queryClient.invalidateQueries({
				queryKey: ['/support/request/list'],
			});

			history.push(`/support/mycases/${data.ticket_number}`);
		},
		onError: snackbarError,
	});
};

export default useSupportRequestCreate;
