import './fonts.css';
import './intro.css';

import React from 'react';
import propTypes from 'prop-types';
import AppConfigGate from 'utility/appConfig/AppConfigGate';
import { ConnectedRouter } from 'connected-react-router';
import {
	StylesProvider,
	ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import './Mui6ClassNameGenerator'; // must be imported before theme
import { lightTheme } from 'undercurrent/themes';
import {
	ThemeProvider as ThemeProvider6,
	createTheme as createTheme6,
	CssBaseline,
} from '@mui/material';

import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import OpenIDProviderWrapper from 'utility/openid/OpenIDProviderWrapper';
import MaintenanceModeGate from 'containers/structural/MaintenanceModeGate';
import ErrorBoundary from 'containers/structural/ErrorBoundary';
import SnackbarProvider from 'components/common/Snackbar/SnackbarProvider';
import RouteHandler from 'components/routers/RouteHandler';
import AuthConnector from 'modules/queries/AuthConnector';
import ChatMiawGate from 'containers/structural/ChatMiawGate';
import { IntroProvider } from 'containers/introjs';
import { history, store } from './store';
import theme from './theme';

const theme6 = createTheme6(lightTheme);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: { refetchOnWindowFocus: false, retry: false },
	},
});

export function Providers({ children }) {
	return [
		(child) => <ReduxProvider store={store}>{child}</ReduxProvider>,
		(child) => (
			<QueryClientProvider client={queryClient}>{child}</QueryClientProvider>
		),
		(child) => <MuiThemeProvider theme={theme}>{child}</MuiThemeProvider>,
		(child) => <StylesProvider injectFirst>{child}</StylesProvider>,
		(child) => <ThemeProvider theme={theme}>{child}</ThemeProvider>,
		(child) => <ThemeProvider6 theme={theme6}>{child}</ThemeProvider6>,
		(child) => <AppConfigGate>{child}</AppConfigGate>,
		(child) => <ConnectedRouter history={history}>{child}</ConnectedRouter>,
		(child) => <OpenIDProviderWrapper>{child}</OpenIDProviderWrapper>,
		(child) => <MaintenanceModeGate>{child}</MaintenanceModeGate>,
		(child) => <ChatMiawGate>{child}</ChatMiawGate>,
		(child) => <ErrorBoundary fullScreen>{child}</ErrorBoundary>,
		(child) => <SnackbarProvider>{child}</SnackbarProvider>,
		(child) => <IntroProvider>{child}</IntroProvider>,
	]
		.reverse()
		.reduce((child, provider) => provider(child), children);
}

Providers.propTypes = {
	children: propTypes.node.isRequired,
};

const App = () => (
	<Providers>
		<CssBaseline />
		<AuthConnector />
		<RouteHandler />
	</Providers>
);

export default App;
