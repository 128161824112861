import { useEffect, useRef } from 'react';

/**
 * Reset a state after a delay
 * @param {boolean} trigger Once true, reset is called after delay
 * @param {() => void} reset Callback function called after delay
 * @param {number} [delay] Time in ms
 * @example
 * useDelayReset(open, () => { setOpen(false); }) // Close after 2s
 * @example
 * useDelayReset(anchor !== undefined, () => { setAnchor(undefined); }) // Reset menu anchor after 5s
 */
export function useDelayReset(trigger, reset, delay = 2000) {
	const resetRef = useRef(reset);
	resetRef.current = reset;

	useEffect(() => {
		if (!trigger) return undefined;

		const timer = setTimeout(() => {
			resetRef.current?.();
		}, delay);

		return () => {
			clearTimeout(timer);
		};
	}, [trigger, resetRef, delay]);
}

export default useDelayReset;
