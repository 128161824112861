import React from 'react';

import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import CircleXIcon from 'undercurrent/icons/lucide/CircleX';
import TriangleAlertIcon from 'undercurrent/icons/lucide/TriangleAlert';
import XIcon from 'undercurrent/icons/lucide/X';

import {
	CRITICAL_SEVERITY,
	ERROR_SEVERITY,
	WARNING_SEVERITY,
} from 'utility/constants/notificationConstants';

/**
 * @typedef SeverityIconProps
 * @property {string} severity
 */

/**
 * @param {SeverityIconProps & import('undercurrent/types/svgTypes').LucideSvgProps} props
 */
const SeverityIcon = ({ severity, ...rest }) => {
	if ([CRITICAL_SEVERITY, ERROR_SEVERITY].includes(severity)) {
		return (
			<CircleXIcon
				sx={(theme) => ({ color: theme.palette.uc.danger.icon.strong })}
				{...rest}
			/>
		);
	}
	if (WARNING_SEVERITY === severity) {
		return (
			<TriangleAlertIcon
				sx={(theme) => ({ color: theme.palette.uc.warning.icon.strong })}
				{...rest}
			/>
		);
	}
	return null;
};

/**
 * @typedef AlertItemPropsI
 * @property {import('club-sauce/public/notifications/index.raw').LWApiPublicNotificationDetailsResultRawI} alert
 * @property {() => void} onClick
 * @property {() => void} onDismissClick
 */

/**
 * @param {AlertItemPropsI} props
 */
const AlertItem = ({ alert, onClick, onDismissClick }) => (
	<MenuItem onClick={onClick}>
		<Stack
			direction="row"
			justifyContent="space-between"
			alignItems="center"
			width={1}
			spacing={100}
		>
			<SeverityIcon severity={alert.severity} />
			<Typography variant="label-lg" noWrap={true} flex={1}>
				{alert?.description}
			</Typography>
			<Tooltip placement="bottom" title="Dismiss" variant="primary">
				<IconButton
					variant="barebones"
					size="small"
					onClick={(event) => {
						event.stopPropagation();
						onDismissClick();
					}}
				>
					<XIcon />
				</IconButton>
			</Tooltip>
		</Stack>
	</MenuItem>
);

export default AlertItem;
