/**
 * @typedef Template
 * @property {string} key - The template key, such as 'App'
 * @property {string} value - The template value, such as 'WordPress Optimized'
 */

/**
 * @typedef OsInfo
 * @property {string} os - The os without any additional template information
 * @property {Template?} template - The extracted template information, if any
 */

/**
 * Breaks up a given Operating System string into two 2 parts:
 * 		1. the OS description without the additional template information
 * 		2. the template information (if any)
 *
 * 	Given:
 * 		Ubuntu [App:WordPress Optimized] 22.04 LTS 64-bit Self-Managed
 * 	Returns:
 * 		{
 * 			os:	'Ubuntu 22.04 LTS 64-bit Self-Managed',
 * 			template: {
 * 				key: 'App',
 * 				value: 'WordPress Optimized',
 * 			},
 * 		}
 *
 * @param {string} os
 * @returns {OsInfo}
 */
const extractOsInfo = (os) => {
	const TEMPLATE_START_CHAR = '\\['; // Escape '['
	const TEMPLATE_SPLIT_CHAR = ':';
	const TEMPLATE_END_CHAR = '\\]'; // Escape ']'
	const regex = new RegExp(
		`(.+?)(${TEMPLATE_START_CHAR}.+?${TEMPLATE_SPLIT_CHAR}.+?${TEMPLATE_END_CHAR})(.*)`,
	);
	const match = os.match(regex);

	if (!match) {
		return { os, template: null };
	}

	const [, pre, template, post] = match.map((part) => part.trim());

	const [key, value] = template
		.substring(1, template.length - 1)
		.split(TEMPLATE_SPLIT_CHAR);

	return {
		os: `${pre} ${post}`,
		template: { key, value },
	};
};

export default extractOsInfo;
