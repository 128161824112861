// @ts-check
import { useQuery } from '@tanstack/react-query';
import api from 'modules/queries/api';

/**
 * @param {import('club-sauce/public/account/user/stateData/index.raw').LWApiPublicAccountUserStateDataListForKeyParamsRawI} params
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 */
export const useAccountUserStatedataListforkey = (params, { enabled } = {}) =>
	useQuery({
		queryKey: ['/account/user/statedata/listforkey', params],
		queryFn: () => api.account.user.stateData.apiRaw.listForKey(params),
		enabled,
	});

export default useAccountUserStatedataListforkey;
