// @ts-check
import { useEffect, useState } from 'react';
import useIntroContext from './context';

/**
 * Mark a component as an introjs target
 * @param {string} id
 * @param {React.MutableRefObject<Element|null>} ref
 */
export function useIntroTarget(id, ref) {
	const { setIntroTargets } = useIntroContext();
	const [isVisible, setIsVisible] = useState(false);

	// Check element is in the viewport
	useEffect(() => {
		if (!ref.current) {
			setIsVisible(false);
			return undefined;
		}

		if (!window.IntersectionObserver) return undefined;
		const observer = new window.IntersectionObserver(
			([entry]) => {
				setIsVisible(entry?.isIntersecting);
			},
			{ threshold: 0.9 },
		);
		observer.observe(ref.current);

		return () => {
			observer.disconnect();
		};
	}, [ref]);

	// Store our target in the big map
	useEffect(() => {
		const row = {
			id,
			ref,
			isVisible,
		};

		setIntroTargets((current) => {
			if (id in current) return current;
			const result = {
				...current,
				[id]: row,
			};
			return result;
		});

		return () => {
			setIntroTargets((current) => {
				if (current[id] !== row) return current;
				const { [id]: removedRow, ...result } = current;
				return result;
			});
		};
	}, [id, ref, isVisible, setIntroTargets]);
}

export default useIntroTarget;
