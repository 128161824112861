// @ts-check
import { useQuery } from '@tanstack/react-query';
import api from 'modules/queries/api';

/** @param {{due: number, balance: number}} param */
const getAccountBalanceDisplay = ({ due, balance }) => {
	const dueLessBalance = due - balance;
	return dueLessBalance < 0 ? '0' : dueLessBalance.toFixed(2);
};

/** @param {{overdue: number, balance: number}} param */
const getPastDue = ({ overdue, balance }) => {
	const pastDue = overdue - balance;
	return pastDue < 0 ? 0 : pastDue;
};

/** @param {{due: number, overdue: number}} param */
const getPendingCharges = ({ due, overdue }) => {
	const pendingCharges = due - overdue;
	return pendingCharges < 0 ? 0 : pendingCharges;
};

/** @param {{due: number, overdue: number, balance: number}} param */
const getUnusedPayments = ({ due, overdue, balance }) => {
	const pending = due - overdue;
	const pendingChargesAmount = pending < 0 ? 0 : pending;
	return pendingChargesAmount === 0 ? balance : balance * -1;
};

/**
 * @typedef ExtendedBillingBalance
 * @property {string} accountBalanceDisplay
 * @property {number} pastDue
 * @property {number} pendingCharges
 * @property {number} unusedPayments
 */

/**
 * @typedef {ExtendedBillingBalance & import('club-sauce/public/billing/index.raw').LWApiPublicBillingBalanceResultRawI} BillingBalance
 * */

/**
 * @param {import('club-sauce/public/billing/index.raw').LWApiPublicBillingBalanceResultRawI} data
 * @return {BillingBalance}
 * */
const supplementBillingBalance = (data) => {
	const { balance, due, overdue } = data;

	const accountBalanceDisplay = getAccountBalanceDisplay({
		due: Number(due),
		balance: Number(balance),
	});
	const pastDue = getPastDue({
		overdue: Number(overdue),
		balance: Number(balance),
	});
	const pendingCharges = getPendingCharges({
		due: Number(due),
		overdue: Number(overdue),
	});
	const unusedPayments = getUnusedPayments({
		due: Number(due),
		overdue: Number(overdue),
		balance: Number(balance),
	});

	return {
		...data,
		accountBalanceDisplay,
		pastDue,
		pendingCharges,
		unusedPayments,
	};
};

/**
 * @param {object} options
 * @param {import('@tanstack/react-query').UseQueryOptions['enabled']} [options.enabled]
 */
const useBillingBalance = ({ enabled } = {}) =>
	useQuery({
		queryKey: ['/billing/balance'],
		queryFn: () => api.billing.apiRaw.balance(),
		enabled,
		select: supplementBillingBalance,
	});

export default useBillingBalance;
