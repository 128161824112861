// @ts-check
import { useQuery } from '@tanstack/react-query';
import api from 'modules/queries/api';

/**
 * @param {import('club-sauce/public/product/index.raw').LWApiPublicProductDetailsParamsRawI} params
 * @param {object} [options]
 * @param {import('@tanstack/react-query').UseQueryOptions['enabled']} [options.enabled]
 * @param {import('@tanstack/react-query').UseQueryOptions['staleTime']} [options.staleTime]
 */
const useProductDetails = (
	params,
	{ enabled = true, staleTime = 10 * 60 * 1000 } = {},
) =>
	useQuery({
		queryKey: ['/product/details', params],
		queryFn: () => api.product.apiRaw.details(params),
		enabled,
		staleTime,
	});

export default useProductDetails;
