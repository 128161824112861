// @ts-check
import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from 'modules/queries/api';
import useSnackbarError from 'utility/hooks/useSnackbarError';

export const useStatedataUpdate = () => {
	const queryClient = useQueryClient();
	const snackbarError = useSnackbarError();

	return useMutation({
		mutationFn:
			/** @param {import('club-sauce/public/account/user/stateData/index.raw').LWApiPublicAccountUserStateDataUpdateParamsRawI} params */
			(params) => api.account.user.stateData.apiRaw.update(params),

		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['/account/user/statedata/listforkey'],
			});
		},

		onError: snackbarError,
	});
};

export default useStatedataUpdate;
