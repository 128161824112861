import React from 'react';
import { useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ShoppingCartIcon from 'undercurrent/icons/lucide/ShoppingCart';
import { cartDetailsSelectors } from 'modules/api/market/cart/detailsModule';
import HookedLink from './HookedLink';

export function CartButton() {
	const count = useSelector(cartDetailsSelectors.getTotalUnits);

	return (
		<Tooltip title="Cart">
			<IconButton
				LinkComponent={HookedLink}
				href="/cart"
				sx={{ width: count > 0 ? 'unset' : undefined, gap: 100 }}
			>
				<ShoppingCartIcon sx={{ height: '16px', width: '16px' }} />
				{count > 0 && (
					<Typography
						variant="label-md"
						sx={(theme) => ({
							color: theme.palette.uc.text.active,
							bgcolor: theme.palette.uc.bg.active,
							display: 'flex',
							paddingX: 100,
							paddingY: 50,
							justifyContent: 'center',
							alignItems: 'center',
							borderRadius: theme.borderRadius.roundedXS,
							height: theme.size.sm,
							lineHeight: 'unset',
						})}
					>
						{count}
					</Typography>
				)}
			</IconButton>
		</Tooltip>
	);
}

export default CartButton;
