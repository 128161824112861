import React from 'react';
import Badge from '@mui/material/Badge';
import Tooltip from 'undercurrent/Tooltip';
import IconButton from '@mui/material/IconButton';
import MenuIcon from 'undercurrent/icons/lucide/Menu';
import CloseIcon from 'undercurrent/icons/lucide/X';
import useNotificationsCurrent from 'modules/queries/notification/useCurrent';
import useLeftHandLayout from '../context';

/**
 * @typedef {React.ComponentProps<typeof IconButton>} MobileMenuButtonPropsI
 * @property {boolean} showAlerts
 */

/**
 * @param {MobileMenuButtonPropsI} props
 */
export const MobileMenuButton = React.forwardRef((props, ref) => {
	const { showAlerts, ...buttonProps } = props;

	const { mobileOpen, setMobileOpen } = useLeftHandLayout();

	const { data: alertsData } = useNotificationsCurrent(undefined, {
		refetchInterval: 30000,
	});
	const showDot = showAlerts && alertsData?.items?.length > 0;

	return (
		<Tooltip title="Menu" ref={ref}>
			<IconButton
				size="small"
				variant="ghost"
				onClick={() => {
					setMobileOpen((c) => !c);
				}}
				{...buttonProps}
			>
				<Badge
					color={showDot ? 'error' : undefined}
					overlap="circular"
					variant="dot"
				>
					{mobileOpen ? (
						<CloseIcon sx={{ height: '16px', width: '16px' }} />
					) : (
						<MenuIcon sx={{ height: '16px', width: '16px' }} />
					)}
				</Badge>
			</IconButton>
		</Tooltip>
	);
});

MobileMenuButton.displayName = 'MobileMenuButton';

export default MobileMenuButton;
