import { SvgIcon, Typography } from '@mui/material';
import React from 'react';

/**
 * @typedef {import('@mui/material').SxProps<import('@mui/material').Theme>} MuiSxStylesI
 */

/** @type MuiSxStylesI */
const baseHoverStyles = {
	backgroundColor: (theme) => theme.palette.uc.bg.medium,
	outline: 'none',
};

/** @type MuiSxStylesI */
const baseStyles = (theme) => ({
	height: theme.size.md,
	overflow: 'hidden',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'stretch',
	color: theme.palette.text.primary,
	textDecoration: 'none',
	paddingY: 100,
	paddingX: 150,
	borderRadius: theme.borderRadius.roundedSm,
	gap: 100,
	':hover': baseHoverStyles,
	':active': baseHoverStyles,
	':focus-visible': {
		outlineOffset: '2px',
		outlineWidth: '2px',
		outlineStyle: 'solid',
		outlineColor: theme.palette.uc.highlight.border.main,
	},
	[theme.breakpoints.down('sm')]: {
		padding: 200,
		height: theme.size.xL,
	},
});

/**
 * @typedef NavLinkPropsI
 * @property {boolean} active
 * @property {React.Node} children
 */

/**
 * @param {NavLinkPropsI} props
 */
export function NavLink(props) {
	const {
		component = 'a',
		active = false,
		startIcon,
		endIcon,
		sx: sxProp,
		children,
		...anchorProps
	} = props;

	/** @type MuiSxStylesI */
	const sxStyles = [baseStyles];
	if (active) sxStyles.push(baseHoverStyles);

	return (
		<Typography
			component={component}
			sx={[...sxStyles, sxProp]}
			{...anchorProps}
		>
			{startIcon && (
				<SvgIcon sx={{ height: '16px', width: '16px' }}>{startIcon}</SvgIcon>
			)}
			<Typography
				component="span"
				variant="label-lg"
				display="block"
				flex="0% 1 1"
				whiteSpace="nowrap"
			>
				{children}
			</Typography>
			{endIcon && (
				<SvgIcon sx={{ height: '16px', width: '16px' }}>{endIcon}</SvgIcon>
			)}
		</Typography>
	);
}
export default NavLink;
