import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import CircleHelpIcon from 'undercurrent/icons/lucide/CircleHelp';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from 'undercurrent/Tooltip';

import { IntroTarget, introTargets } from 'containers/introjs';
import { username as getUsername } from 'modules/auth/authSelectors';
import HookedLink from './HookedLink';

export function TopNavSupport() {
	const history = useHistory();
	const [menuElement, setMenuElement] = useState();
	const open = menuElement !== undefined;

	const currentUser = useSelector(getUsername);

	const handleClose = useCallback(() => {
		setMenuElement();
	}, []);

	const handleChat = useCallback(() => {
		history.push('/support/mycases', { startChat: true });
		handleClose();
	}, [history, handleClose]);

	return (
		<>
			<Tooltip title="Support">
				<IntroTarget introId={introTargets.navHelpMenu}>
					<IconButton
						onClick={({ currentTarget }) => setMenuElement(currentTarget)}
					>
						<CircleHelpIcon sx={{ height: '16px', width: '16px' }} />
					</IconButton>
				</IntroTarget>
			</Tooltip>
			<Menu
				open={open}
				anchorEl={menuElement}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				onClose={handleClose}
			>
				<MenuItem
					component={HookedLink}
					href="/support/mycases"
					onClick={handleClose}
					role="menuitem"
				>
					<ListItemText primary="My cases" />
				</MenuItem>
				<MenuItem onClick={handleChat} role="menuitem">
					<ListItemText primary="Chat with a human" />
				</MenuItem>
				<MenuItem
					component={Link}
					href="https://www.liquidweb.com/help-docs/"
					target="_blank"
					onClick={handleClose}
					role="menuitem"
				>
					<ListItemText primary="Help Docs" />
				</MenuItem>
				<MenuItem
					component={HookedLink}
					href={`/account/users/${currentUser}`}
					onClick={handleClose}
					role="menuitem"
				>
					<ListItemText primary="My support passphrase" />
				</MenuItem>
			</Menu>
		</>
	);
}

export default TopNavSupport;
