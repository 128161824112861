import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

import { selectors as assetDetailsSelectors } from 'modules/api/asset/detailsModule';
import { selectors as listSelectors } from 'modules/api/network/ip/listPublicModule';

const getStateSlice = (state) => state.api.account.limits.ip;

const {
	actions,
	reducer,
	sagas,
	selectors: moduleSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'ACCOUNT_LIMITS_IP',
	url: '/account/limits/ip.json',
});

const isNocworx = createSelector(
	assetDetailsSelectors.capabilities,
	(capabilities) => {
		return !!capabilities?.nocworxPublicIP;
	},
);

const limit = createSelector(
	moduleSelectors.getNativeData,
	(slice) => Number(slice?.limit) + Number(slice?.limit_ipv6),
);

const limitWithoutIPv6 = createSelector(
	moduleSelectors.getNativeData,
	(slice) => Number(slice?.limit),
);

const openstackLimit = createSelector(moduleSelectors.getStateSlice, (slice) =>
	slice.getIn(['data', 'nocworx_openstack_limit']),
);

const serverIpLimit = createSelector(
	limitWithoutIPv6,
	openstackLimit,
	isNocworx,
	(limit_, openstackLimit_, isNocworx_) =>
		isNocworx_ ? openstackLimit_ : limit_,
);

const remaining = createSelector(
	listSelectors.getTotalItems,
	serverIpLimit,
	(total, serverIpLimit_) => {
		return serverIpLimit_ - total;
	},
);

const selectors = {
	limit,
	limitWithoutIPv6,
	openstackLimit,
	isNocworx,
	serverIpLimit,
	remaining,
	...moduleSelectors,
};

export { actions, reducer, sagas, selectors };
