import React from 'react';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Button from 'undercurrent/Button';
import XIcon from 'undercurrent/icons/lucide/X';

import useResolve from 'modules/queries/notification/useResolve';

/**
 * @typedef DismissAlertDialogProps
 * @property {number} notificationId
 * @property {() => void} onCloseClick
 */

/**
 * @param {DismissAlertDialogProps & import('@mui/material/Dialog').DialogProps} props
 */
const DismissAlertDialog = ({ notificationId, onCloseClick, ...rest }) => {
	const { mutate: resolve, isLoading } = useResolve();

	const handleSubmit = () =>
		resolve({ id: notificationId }, { onSuccess: onCloseClick });

	return (
		<Dialog onClose={onCloseClick} {...rest}>
			<DialogTitle>
				<Stack
					flexDirection="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<Box>Dismiss alert</Box>
					<IconButton variant="barebones" onClick={onCloseClick}>
						<XIcon />
					</IconButton>
				</Stack>
			</DialogTitle>
			<DialogContent>
				<Typography variant="body-md">
					Are you sure you wish to dismiss this alert? You won&apos;t be able to
					access it again once dismissed.
				</Typography>
			</DialogContent>
			<DialogActions sx={{ justifyContent: 'end' }}>
				<Button variant="ghost" onClick={onCloseClick} disabled={isLoading}>
					Cancel
				</Button>
				<Button color="danger" onClick={handleSubmit} loading={isLoading}>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DismissAlertDialog;
