// @ts-check

export const introTargets = {
	navMediumButton: 'NavMediumButton',
	navHomeSection: 'NavHomeSection',
	navProjectsSection: 'NavProjectsSection',
	navServersSections: 'NavServersSections',
	navAccountBalance: 'NavAccountBalance',
	navDesktopCreateAndDeploy: 'NavDesktopCreateAndDeploy',
	navHelpMenu: 'NavHelpMenu',
	navAccountMenu: 'NavAccountMenu',
};

/**
 * @typedef {typeof introTargets[keyof typeof introTargets]} IntroTargetT
 */

/**
 * @typedef IntroConfigStepI
 * @prop {Element} [element]
 * @prop {string} [elementTarget] Element key defined by useIntroTarget
 * @prop {string} [title]
 * @prop {string} [intro]
 * @prop {boolean} [allowInvisible]
 * @prop {import('intro.js/src/core/steps').TooltipPosition} [position]
 */

/**
 * @typedef IntroTourConfigI
 * @prop {IntroConfigStepI[]} steps
 */

/** @type {Record<string,IntroTourConfigI>} */
export const tourConfigs = {
	navcollapsed354: {
		steps: [
			{
				elementTarget: introTargets.navMediumButton,
				title: 'Navigation',
				intro: 'Access the collapsed main navigation via the menu button',
				position: 'right',
			},
		],
	},
	navmain354: {
		steps: [
			{
				elementTarget: introTargets.navHomeSection,
				title: 'Your Liquid Web',
				intro:
					'Explore services and save favorite portal sections for quick access',
				position: 'right',
			},
			{
				elementTarget: introTargets.navProjectsSection,
				title: 'Projects',
				intro: 'Organize hosting services into easy-to-manage projects',
				position: 'right',
			},
			{
				elementTarget: introTargets.navServersSections,
				title: 'Your Servers',
				intro: 'View and manage your servers in one place',
				position: 'right',
			},
			{
				elementTarget: introTargets.navAccountBalance,
				title: 'Account Balance',
				intro: 'Check and manage your account balance',
				position: 'right',
				scrollTo: 'off',
			},
			{
				elementTarget: introTargets.navDesktopCreateAndDeploy,
				title: 'Grow your hosting',
				intro: 'Easily create and deploy new hosting services',
				position: 'bottom',
			},
			{
				elementTarget: introTargets.navHelpMenu,
				title: 'Help',
				intro: 'Access Help Docs, common issues, and Support chat',
				position: 'bottom',
			},
			{
				elementTarget: introTargets.navAccountMenu,
				title: 'Account',
				intro: 'Manage secure notes, your account, and user settings',
				position: 'bottom',
			},
		],
	},
};

export default tourConfigs;
