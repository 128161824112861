import React from 'react';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

/**
 * @typedef {import('@mui/material').SxProps<import('@mui/material').Theme>} MuiSxStylesI
 */

/** @type {MuiSxStylesI} */
const baseHoverStyles = (theme) => ({
	color: theme.palette.uc.highlight.text.main,
	bgcolor: theme.palette.uc.highlight.bg.weak,
});

/** @type {MuiSxStylesI} */
const baseStyles = (theme) => ({
	color: theme.palette.text.primary,
	paddingX: 200,
	paddingY: 150,
	gap: 150,
	':hover': baseHoverStyles,
	':active': baseHoverStyles,
	':focus-visible': {
		outlineOffset: '2px',
		outlineWidth: '2px',
		outlineStyle: 'solid',
		outlineColor: theme.palette.uc.highlight.border.main,
	},
});

/**
 * @typedef {React.ComponentProps<typeof MenuItem>} CreateItemPropsI
 * @property {React.Node?} icon
 * @property {React.Node?} endIcon
 * @property {string?} description
 * @property {React.Node} children
 */

/**
 * @param {CreateItemPropsI} props
 */
export function CreateItem(props) {
	const { icon, endIcon, description, children, sx, ...menuItemProps } = props;

	return (
		<MenuItem sx={[baseStyles, sx]} {...menuItemProps}>
			{icon && <ListItemIcon>{icon}</ListItemIcon>}
			<ListItemText
				primary={children}
				secondary={
					description && (
						<Typography variant="body-xs" component="p">
							{description}
						</Typography>
					)
				}
			/>
			{endIcon && (
				<ListItemIcon sx={{ justifyContent: 'flex-end' }}>
					{endIcon}
				</ListItemIcon>
			)}
		</MenuItem>
	);
}
export default CreateItem;
