import React, { useCallback, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import SearchIcon from 'undercurrent/icons/lucide/Search';
import Tooltip from 'undercurrent/Tooltip';
import useKeyCmd, { CMD_KEY } from 'utility/hooks/useKeyCmd';
import SearchModal from 'components/Search/SearchModal';
import useLeftHandLayout from '../context';

export function TopNavSearch() {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { screenSize } = useLeftHandLayout();
	const allowKeyCmd = screenSize !== 'small';

	const handleSearch = useCallback(() => {
		setIsModalOpen(true);
	}, []);

	const handleCloseModal = useCallback(() => {
		setIsModalOpen(false);
	}, []);

	useKeyCmd('KeyK', allowKeyCmd ? handleSearch : undefined);
	const titlePostfix = allowKeyCmd ? ` ${CMD_KEY} K` : '';

	return (
		<>
			<Tooltip title={`Search${titlePostfix}`}>
				<IconButton onClick={handleSearch}>
					<SearchIcon sx={{ height: '16px', width: '16px' }} />
				</IconButton>
			</Tooltip>
			<SearchModal open={isModalOpen} onClose={handleCloseModal} />
		</>
	);
}

export default TopNavSearch;
