// @ts-check

import { useEffect, useRef, useState } from 'react';

import useDebounce from 'utility/effects/useDebounce';

/**
 * @param {object} param0
 * @param {number} [param0.initWidth]
 * @param {number} [param0.delay]
 */
const useDebouncedWidth = ({ initWidth, delay = 1000 }) => {
	const ref = useRef();

	const [width, setWidth] = useState(initWidth);
	const debounceWidth = useDebounce(width, delay);

	// Handle page resize
	useEffect(() => {
		const { current: element } = ref;

		if (!element) return () => {};

		const resizeObserver = new ResizeObserver((entries) => {
			entries.forEach(({ contentRect }) => {
				setWidth(contentRect.width);
			});
		});

		resizeObserver.observe(element);

		return () => {
			resizeObserver.unobserve(element);
		};
	}, [ref]);

	return { debounceWidth, ref };
};

export default useDebouncedWidth;
