// @ts-check
import { useEffect, useMemo, useRef } from 'react';
import tourConfigs from './config';
import useIntroContext from './context';
import { useIntroCompleted, useIntroPaused } from './useIntroStates';

const INTRO_PAUSED_MS = 72e6; // 20 hours

/**
 * @param {{id:string}} props
 */
export function IntroTourLoader(props) {
	const { id } = props;
	const { startTour, introTargets } = useIntroContext();
	const startedRef = useRef(false);

	const [paused, setPaused, { isBusy: isPausedBusy, isError: isPausedError }] =
		useIntroPaused(id);

	const [
		isComplete,
		setIsComplete,
		{ isBusy: isCompleteBusy, isError: isCompleteError },
	] = useIntroCompleted(id);

	const isPaused = isPausedBusy || Date.now() < (paused || 0) || isPausedError;
	const isNotComplete = !isCompleteBusy && !isComplete && !isCompleteError;
	const allowTour = isNotComplete && !isPaused;

	// Store the tour steps
	const tourSteps = useMemo(() => {
		if (!allowTour) return undefined;

		const { steps: stepsConfig } = tourConfigs[id];

		const steps = [];
		for (const step of stepsConfig) {
			const { elementTarget, allowInvisible, ...stepOption } = step;

			if (elementTarget) {
				const target = introTargets[elementTarget];

				// Bail if the target doesnt exist yet
				if (!target) return undefined;
				const { ref, isVisible } = target;

				// Bail if something happened to the ref
				if (!ref.current) return undefined;

				// Bail if the target isnt visible
				if (!isVisible && !allowInvisible) return undefined;

				stepOption.element = ref.current;
			}

			// Bail if the element should exist but doesnt
			if ('element' in stepOption && !stepOption.element) return undefined;

			steps.push(stepOption);
		}
		return steps;
	}, [id, allowTour, introTargets]);

	// Start tour _when able to_
	useEffect(() => {
		if (!tourSteps) return;

		// Dont run it twice per load
		if (startedRef.current) return;
		startedRef.current = true;

		startTour(tourSteps, {
			onStarted: () => {
				// Don't show for a while even if they didnt finish
				setPaused(Date.now() + INTRO_PAUSED_MS);
			},
			onComplete: () => {
				setIsComplete(true);
				setPaused(undefined);
			},
		});
	}, [tourSteps, setPaused, setIsComplete, startTour]);

	return null;
}

export default IntroTourLoader;
