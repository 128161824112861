import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import LWCard, { types as lwCardTypes } from 'components/common/LWCard';
import LWTypography from 'components/common/LWTypography';
import Acronis from './Acronis';

/** @param {import('modules/queries/asset/useDetails').AssetDetails} props */
const Backups = (props) => {
	const {
		assetType: serverType,
		backupsAndStorage,
		isBeyondHosting,
		isStorm,
		uniq_id: uniqId,
	} = props;
	const { image } = backupsAndStorage || {};
	const {
		name: imageName = '',
		date: imageDate = '',
		count: imageCount = 0,
	} = image || {};
	const imageText = image?.name ? `${imageName} - ${imageDate}` : 'None';
	const hasAcronis = Boolean(backupsAndStorage?.acronis);
	const cardTitle = serverType === 'dedicated' ? 'Backups' : 'Backups & Images';

	const links = !isBeyondHosting
		? [
				{
					href: `/servers/${uniqId}/backups/`,
					text: `View ${cardTitle}`,
					eventOptions: {
						action: 'Backups Page',
						label: 'Backups Box Card',
					},
				},
			]
		: null;

	return (
		<LWCard title={cardTitle} type={lwCardTypes.LIGHT} links={links}>
			{hasAcronis && <Acronis data-testid="Backups__Acronis" {...props} />}
			{isStorm && (
				<Box
					data-testid="Backups__Images"
					display="flex"
					flexDirection="column"
					pb={2}
				>
					<LWTypography color="textSecondary" variant="caption">
						{`Images (${imageCount})`}
					</LWTypography>
					<LWTypography variant="body1">{imageText}</LWTypography>
				</Box>
			)}
			{isBeyondHosting && (
				<LWTypography data-testid="Backups__BeyondHosting">
					Please contact support for access to your backups
				</LWTypography>
			)}
		</LWCard>
	);
};

Backups.propTypes = {
	acronisId: PropTypes.string,
	assetType: PropTypes.string.isRequired,
	isBeyondHosting: PropTypes.bool,
	uniq_id: PropTypes.string.isRequired,
};

export default Backups;
