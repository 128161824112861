// @ts-check
import useUserMetaState from 'modules/queries/account/user/stateData/useUserMetaState';

/**
 * Mark a tour as paused until epochMs time
 * @param {string} id tour id
 */
export function useIntroPaused(id) {
	return useUserMetaState(
		`intro:${id}:paused`,
		/** @type {number | undefined} */ (undefined),
	);
}

/**
 * Mark a tour as complete for the user
 * @param {string} id tour id
 */
export function useIntroCompleted(id) {
	return useUserMetaState(`intro:${id}:complete`, false);
}
