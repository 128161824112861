// @ts-check
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useSnackbarError from 'utility/hooks/useSnackbarError';
import api from 'modules/queries/api';

const useNotificationResolve = () => {
	const queryClient = useQueryClient();
	const snackbarError = useSnackbarError();

	return useMutation({
		mutationFn:
			/** @param {import('club-sauce/public/notifications/index.raw').LWApiPublicNotificationResolveParamsRawI} params */
			(params) => api.notifications.apiRaw.resolve(params),

		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['/notifications/current'] });
		},

		onError: snackbarError,
	});
};

export default useNotificationResolve;
