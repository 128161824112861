import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import LayersIcon from 'undercurrent/icons/lucide/Layers2';
import CloudIcon from 'undercurrent/icons/lucide/Cloud';
import ServerIcon from 'undercurrent/icons/lucide/Server';
import ArrowRightIcon from 'undercurrent/icons/lucide/ArrowRight';
import WordpressIcon from 'undercurrent/icons/platform/WordPress';
import CreateItem from './CreateItem';
import HookedLink from './HookedLink';

/**
 * Function that returns menu items to ensure proper keyboard navigation.
 * MUI's Select/Menu requires MenuItem components to be direct descendants.
 * Using a function instead of a component maintains this relationship.
 * @see {@link https://github.com/mui/material-ui/issues/31006}
 * @param {{ onClose: () => void }} params
 */
export const createMenuContent = ({ onClose }) => [
	<CreateItem
		key="vps"
		component={Link}
		href="https://www.liquidweb.com/configuration/cloud-studio/?type=vps"
		target="_blank"
		icon={
			<LayersIcon
				sx={(theme) => ({
					fontSize: `${theme.size.sm}!important`,
				})}
			/>
		}
		description="Control, customization, security"
	>
		<Typography variant="label-xl">VPS hosting</Typography>
	</CreateItem>,
	<CreateItem
		key="cloud"
		component={Link}
		href="https://www.liquidweb.com/cloud-hosting/dedicated-cloud-server/"
		target="_blank"
		icon={
			<CloudIcon
				sx={(theme) => ({
					fontSize: `${theme.size.sm}!important`,
				})}
			/>
		}
		description="Reliable, scalable, secure"
	>
		<Typography variant="label-xl">Cloud hosting</Typography>
	</CreateItem>,
	<CreateItem
		key="dedicated"
		component={Link}
		href="https://www.liquidweb.com/dedicated-server-hosting/managed-servers/"
		target="_blank"
		icon={
			<ServerIcon
				sx={(theme) => ({
					fontSize: `${theme.size.sm}!important`,
				})}
			/>
		}
		description="Private, secure, fully featured"
	>
		<Typography variant="label-xl">Dedicated servers</Typography>
	</CreateItem>,
	<CreateItem
		key="wordpress"
		component={Link}
		href="https://www.liquidweb.com/wordpress-hosting/"
		target="_blank"
		icon={
			<WordpressIcon
				sx={(theme) => ({
					fontSize: `${theme.size.sm}!important`,
					path: {
						fill: theme.palette.uc.icon.main,
					},
				})}
			/>
		}
		description="Optimized, experienced, scalable"
	>
		<Typography variant="label-xl">WordPress hosting</Typography>
	</CreateItem>,
	<Divider key="divider" />,
	<CreateItem
		key="marketplace"
		component={HookedLink}
		href="/shop/marketplace"
		onClick={onClose}
		endIcon={<ArrowRightIcon />}
		sx={{
			borderRadius: { xs: 0, sm: (theme) => theme.borderRadius.roundedSm },
			borderTopLeftRadius: 0,
			borderTopRightRadius: 0,
		}}
	>
		Visit Marketplace
	</CreateItem>,
];

/**
 * Component that returns MUI's MenuItem components.
 * @param {Object} props
 * @param {() => void} props.onClose
 */
function CreateMenuContent(props) {
	return createMenuContent(props);
}

export default CreateMenuContent;
